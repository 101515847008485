import React from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { changeSelectedYear, selectedYear } from "redux/app/reducers/tabsSlice";

const { Option } = Select;

const DropDownComponent = ({ options, onChange }) => {
  const dispatch = useDispatch();
  const yearSelected = useSelector(selectedYear);

  const handleChange = (value) => {
    if (onChange) {
      onChange(value);
    }
    dispatch(changeSelectedYear(value));
  };

  return (
    <Select
      defaultValue={yearSelected}
      className="header-custom-select"
      onChange={handleChange}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
    >
      {options.map((opt) => (
        <Option key={opt.value} value={opt.value}>
          {opt.label}
        </Option>
      ))}
    </Select>
  );
};

export default DropDownComponent;
