import React, { useState, useEffect } from "react";
import { Layout, Badge, Tooltip, Row, Col } from "antd";
import FirstRow from "./FirstRow/FirstRow";
import OtherRows from "./OtherRows";
import "./summary.css";
import fetchRecordsSummary from "redux/app/url";
import InfoCircle from "Assets/Icons/InfoCircle";
import { useSelector } from "react-redux";
import Airtable from "airtable";
import { apiData } from "redux/app/index";
import PageLoader from "Components/Shared/PageSpinner"; 

const Summary = ({ yearSelected, baseId }) => {
  const apiKeys = useSelector(apiData);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    const fetchData = async (base) => {
      try {
        const result = await fetchRecordsSummary(`${yearSelected}`, base);
        console.log("Fetched data:", result);
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); 
      }
    };

    if (
      apiKeys &&
      (apiKeys.apiKay || localStorage.getItem("apiKey")) &&
      (apiKeys.baseId || baseId)
    ) {
      console.log("API Key:", apiKeys.apiKay || localStorage.getItem("apiKey"));
      console.log("Base ID:", apiKeys.baseId || baseId);
      let base = new Airtable({
        apiKey: apiKeys.apiKay
          ? apiKeys.apiKay
          : localStorage.getItem("apiKey"),
      }).base(apiKeys.baseId ? apiKeys.baseId : baseId);
      fetchData(base);
    }
  }, [yearSelected, apiKeys, baseId]);

  return (
    <div style={{ position: "relative" }}>
      {loading && <PageLoader />} {/* Show PageLoader while loading */}
      <Row justify="space-between" className="summary-header">
        <Col>
          <div className="summary-heading-text">
            Summary {yearSelected}
            <Tooltip
              title={
                <div className="tooltip-left-align">
                  *Summary values are in Year-to-Date format and include
                  upcoming bookings.
                </div>
              }
            >
              <Badge count={<InfoCircle />} />
            </Tooltip>
          </div>
        </Col>
        <Col></Col>
      </Row>
      <div>
        {data ? (
          <>
            <FirstRow data={data} />
            <OtherRows data={data} />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Summary;
