import formattedAmount from "util/formattedString";
import SingleCard from "./SingleCard";

const OtherRows = ({ data }) => {
  return (
    <div className="OtherRowsContainr">
      <div className="summary-2x-row">
        <SingleCard
          fValue={data?.["Total Bookings"]}
          sValue={formattedAmount(data?.["Rate per Booking"], true)}
          fTitle="Total Bookings"
          sTitle="Rate per Booking"
        />
        <SingleCard
          fValue={data?.["Guest Nights"]}
          sValue={formattedAmount(data?.["Guest Nightly Rate"], true)}
          fTitle="Guest Nights"
          sTitle="Rate per Guest Night"
        />
      </div>
      <div className="summary-2x-row">
        <SingleCard
          fValue={`${data?.["Owner Bookings"] ?? "-"}/${
            data?.["Owner Nights"] ?? "-"
          }`}
          sValue={formattedAmount(data?.["Rate per Owner Booking"], true)}
          fTitle="Owner bookings / nights"
          sTitle="Value per Owner Booking"
        />

        <SingleCard
          fValue={formattedAmount(data?.["Val of Owner Bookings"], true)}
          sValue={formattedAmount(data?.["Owner Nightly Rate"], true)}
          fTitle="Value of owner booking(s)"
          sTitle="Value per owner Night"
        />
      </div>
    </div>
  );
};

export default OtherRows;
