import styled from "styled-components";

export const ModelSecondaryCardWraper = styled.div`
  padding: 12px 0px 10px;
  margin-bottom: 12px;
  border-width: 4px;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-width: 0;
  transition: all 0.3s ease-in-out;

  .card-title {
    color: ${(props) => props.theme.colors.black};
    font-family: "Montserrat";
    font-size: 13px;
    line-height: 13px;
    font-weight: 400;
    color: #110f0e;
    margin-bottom: 12px;
  }
  .card-value {
    color: ${(props) => props.theme.colors.black};
    font-family: "Montserrat";
    font-size: 24px;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    min-width: 105px;
  }
`;
