import React from "react";
import { Button } from "antd";
import "./styles.js";
import { HeaderInfo, HeaderWrapper } from "./styles.js";
import { useSelector, useDispatch } from "react-redux";
import {
  changeSelectedMonth,
  selectedMonth,
} from "redux/app/reducers/tabsSlice.js";
import UserInfo from "./UserInfo/index.jsx";
import PreviousIcon from "Assets/Icons/PreviousIcon.jsx";
import NextIcon from "Assets/Icons/NextIcon.jsx";
import { DropDownMonthly } from "Components/Shared/MonthlyDropDown/monthlyDropDown.js";
import { generateDateOptions } from "util/generateYear.js";
const Header = (props) => {
  const monthSelected = useSelector(selectedMonth);
  const userType = localStorage.getItem("userType");
  const dispatch = useDispatch();

  const handleNextMonth = () => {
    const baseId = localStorage.getItem("baseId");
    const options = generateDateOptions(baseId);
    const currentIndex = options.findIndex(
      (option) => option.value === monthSelected
    );
    if (currentIndex !== -1 && currentIndex < options.length - 1) {
      const nextMonth = options[currentIndex + 1].value;
      dispatch(changeSelectedMonth(nextMonth));
    }
  };

  const handlePreviousMonth = () => {
    const baseId = localStorage.getItem("baseId");
    const options = generateDateOptions(baseId);
    const currentIndex = options.findIndex(
      (option) => option.value === monthSelected
    );
    if (currentIndex > 0) {
      const previousMonth = options[currentIndex - 1].value;
      dispatch(changeSelectedMonth(previousMonth));
    }
  };

  return (
    <>
      <HeaderWrapper>
        <div className="main-header-heading-overview">Monthly Statement</div>
      </HeaderWrapper>
      {userType !== "ADMIN" && <UserInfo />}
      <HeaderInfo>
        <div className="header-month">
          &nbsp;
          {monthSelected}
        </div>
        <div className="navigation-button">
          <Button onClick={handlePreviousMonth}>
            <PreviousIcon /> Previous Month
          </Button>
          <Button onClick={handleNextMonth}>
            Next Month <NextIcon />
          </Button>
        </div>
      </HeaderInfo>
    </>
  );
};

export default Header;
