import { ParagraphWraper } from "./styles";

const Paragraph = ({ children, className }) => {
  return (
    <ParagraphWraper>
      <p className={className}>{children}</p>
    </ParagraphWraper>
  );
};

export default Paragraph;
