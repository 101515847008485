import styled from "styled-components";
import { Tooltip } from "antd";

export const ParentWrapper = styled.div`
  .ant-menu-item {
    border-radius: 8px;
    font-size: 13px;
    line-height: 24px;
    gap: 8px;
    display: flex;
    align-items: center;
  }
`;
export const GridWraper = styled.div`
  padding: 80px 20px;
`;
export const summaryWrapper = styled.div`
  padding: 12px;
`;
export const CustomTooltip = styled(Tooltip)`
  margin-left: 8px;
  .ant-tooltip-arrow {
    &::before {
      background-color: #fff; /* Set the background color of the arrow */
      border-color: #fff transparent transparent transparent; /* Set the border color of the arrow */
    }
  }
`;
