import styled from 'styled-components';

export const SummaryWrapper = styled.div`
  padding: 0px 42px 0px 20px;
  margin-bottom: 24px;
  .summary-card-holder {
    flex-direction: row;
    gap: 20px;
    width: 100%;
    background: #fafafa;
    border-radius: 8px;
  }
  .summary-right-col {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    padding: 0px 0px 0px 20px;
    justify-content: center;
  }
  .logo-holder {
    border-radius: 8px;
    display: flex;
    width: 100%;
    height: 100%;
    padding: 56px 0px;
    justify-content: center;
    background: rgb(250, 250, 250);

    border: 1px solid #e7e7e7;
    .ant-image {
      margin: auto;
    }
  }
  .summary-right-col .last-col-card {
    padding: 20px 8px;
    background: #fafafa;
    border-width: 4px;
    border-radius: 8px;
    border: 1px solid transparent;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    transition: all 0.3s ease-in-out;
  }
  .last-col-card:hover {
    background: rgba(253, 250, 231, 1);
    border: 1px solid rgba(231, 209, 19, 1);
  }
  .summary-card-title {
    color: ${(props) => props.theme.colors.black};
    font-family: 'Montserrat';
    font-size: 13px;
    line-height: 13px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .summary-card-value {
    color: ${(props) => props.theme.colors.black};
    font-family: 'Montserrat';
    font-size: 24px;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
  }
  .monthly-summary-first-row {
    padding: 12px 0px;
    background: #fafafa;
    border-width: 4px;
    border-radius: 8px;

    border: 1px solid #e7e7e7;
  }
  .last-col-card sup {
    font-weight: 600;
    top: -0.7em;
    font-size: 50%;
  }
`;
