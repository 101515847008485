import React, { useState } from "react";
import { Table } from "antd";
import "../table.css";
import BlueCheck from "Assets/Icons/BlueCheck";
import MonthlyPreview from "Pages/Dashboard/Modals/MonthlyPreview";

const columns = [
  {
    title: "Month",
    dataIndex: "monthly_year",
    key: "monthly_year",
    className: "boldColumn",
  },
  {
    title: "Monthly Revenue",
    dataIndex: "monthly_revenue",
    key: "monthly_revenue",
    className: "boldColumn",
  },
  {
    title: "Avg Booking Rate",
    dataIndex: "avg_rate_booking",
    key: "avg_rate_booking",
  },
  {
    title: "Avg Nightly Rate",
    dataIndex: "avg_nighty_rate",
    key: "avg_nighty-rate",
  },
  {
    title: "Bookings",
    dataIndex: "booking",
    key: "booking",
  },
  {
    title: "Nights",
    dataIndex: "nights",
    key: "nights",
  },
  {
    title: "Owner Bookings",
    dataIndex: "owner_bookings",
    key: "owner_bookings",
    className: "table-content-tick-icon",
    render: (text) =>
      text ? (
        <BlueCheck color="#0098EE" className="table-content-tick-icon" />
      ) : (
        <span className="empty-indicator">_</span>
      ),
  },
];

const TableContent = ({ data, rawData, ...rest }) => {
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleRowClick = (record) => {
    const { key } = record;
    if (key) {
      setSelectedRowData(rawData.find((record) => record.monthID === key));
    }

    setIsModalVisible(true);
  };
  console.log("Here is our raw data we have ", selectedRowData);
  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="content-table-container">
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        onRow={(record) => ({
          onClick: () => handleRowClick(record),
        })}
      />
      <MonthlyPreview
        {...rest}
        rawData={selectedRowData}
        visible={isModalVisible}
        onClose={handleModalClose}
      />
    </div>
  );
};

export default TableContent;
