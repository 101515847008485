import styled from "styled-components";

export const ParagraphWraper = styled.div`
  .lgBold {
    color: ${(props) => props.theme.colors.black};
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }
  .mdMedium {
    color: ${(props) => props.theme.colors.black};
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
  }
  .smRegular {
    color: ${(props) => props.theme.colors.black};
    font-family: "Montserrat";
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0em;
  }
  .selectRegular {
    color: ${(props) => props.theme.colors.black};
    font-family: "poppins";
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    margin-bottom: 4px;
  }
`;
