import React from "react";
import { Table, Button, Space, Popconfirm } from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import "./styles.css";

const UserTable = ({ users, onEdit, onDelete, onFilter }) => {
  const pageSize = 9;

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => text || record.email,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "userType",
      render: (text, record) => {
        const formatText = (str) =>
          str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        return formatText(text || record.airTableType);
      },
    },
    {
      title: "Unit Access",
      dataIndex: ["apiData", "baseId"],
      key: "unitNo",
      render: (baseId) => {
        if (baseId === "appkscyvJutKmwgNB") return "611/613";
        if (baseId === "appn8T3UykPjpTnV2") return "723/725";
        return baseId;
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="link"
            className="edit-icon"
            icon={<EditOutlined />}
            onClick={() => onEdit(record)}
          />
          <Button
            type="link"
            className="filter-icon"
            icon={<FilterOutlined />}
            onClick={() => onFilter(record)}
          />
          <Popconfirm
            title="Are you sure to delete this user?"
            onConfirm={() => onDelete(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="link"
              className="delete-icon"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table
      dataSource={users}
      columns={columns}
      rowKey="_id"
      className="user-table"
      pagination={users.length > pageSize ? { pageSize } : false}
    />
  );
};

export default UserTable;
