import axios from "axios";

const API_URL = "https://book-luxury-backend.onrender.com/user";
// const API_URL = "http://localhost:3000/user";

const axiosInstance = axios.create({
  baseURL: API_URL,
});

const MAX_RETRIES = 5;
const RETRY_DELAY = 1000; // Initial delay in milliseconds

const sleep = (milliseconds) =>
  new Promise((resolve) => setTimeout(resolve, milliseconds));

const retryRequest = async (fn, retries = MAX_RETRIES, delay = RETRY_DELAY) => {
  try {
    return await fn();
  } catch (error) {
    if (error.response && error.response.status === 429 && retries > 0) {
      console.warn(`Retrying request... (${retries} retries left)`);
      await sleep(delay);
      return retryRequest(fn, retries - 1, delay * 2);
    }
    throw error;
  }
};

export const addUser = async (user) => {
  return retryRequest(() => axiosInstance.post("/users", user));
};

export const updateUser = async (id, user) => {
  return retryRequest(() => axiosInstance.put(`/users/${id}`, user));
};

export const deleteUser = async (id) => {
  return retryRequest(() => axiosInstance.delete(`/users/${id}`));
};

export const getUsers = async () => {
  return retryRequest(() => axiosInstance.get("/users"));
};
