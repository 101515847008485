import React, { useState, useEffect } from "react";
import { Layout, Button, Drawer, Form, Input, message, Row, Col, Select } from "antd";
import UserTable from "Components/Shared/Tables/userTable";
import {
  addUser,
  updateUser,
  getUsers,
  deleteUser,
} from "apiLayer/userService";
import AppLayout from "Layout/Layout";
import CustomDropdown from "Components/Shared/CustomDropDown";
import PageLoader from "Components/Shared/PageSpinner";
import CustomButton from "Components/Shared/PrimaryButton";
import { FilterOutlined } from "@ant-design/icons";

const { Content } = Layout;

const ManageUsers = () => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isFilterDrawerVisible, setIsFilterDrawerVisible] = useState(false);
  const [editingUser, setEditingUser] = useState(null);

  const [userId, setUserId] = useState('');
  const [users, setUsers] = useState([]);
  const [form] = Form.useForm();
  const [filterForm] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      setLoading(true);

      const data = await getUsers();
      setUsers(data);
    } catch (error) {
      message.error("Failed to fetch users");
    } finally {
      setLoading(false);
    }
  };

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const showFilterDrawer = (user) => {

    console.log(user?._id,"user")
    filterForm.setFieldsValue({
      name: user.name,
      email: user.email,
      months: user.months,   
      years:user.years
    });
    setUserId(user?._id)

    setIsFilterDrawerVisible(true);
  };



  const handleCancel = () => {
    setIsDrawerVisible(false);
    setEditingUser(null);
    form.resetFields();
  };

  const handleFilterCancel = () => {
    setIsFilterDrawerVisible(false);

    filterForm.resetFields();
  };

  const handleSave = async (values) => {
    try {
      values.apiData = { baseId: values.unitNumber };
      values.role = values.airTableType;
      console.log("Form values:", values);

      if (editingUser) {
        await updateUser(editingUser._id, values);
        message.success("User updated successfully");
      } else {
        await addUser(values);
        message.success("User added successfully");
      }
      fetchUsers();
      handleCancel();
    } catch (error) {
      console.error("Error saving user:", error);
      message.error("Failed to save user");
    }
  };

  const handleEdit = (user) => {
    user.unitNumber = user.apiData.baseId;
    setEditingUser(user);
    form.setFieldsValue({
      ...user,
      password: "",
      airTableType: user.airTableType,
      unitNumber: user.unitNumber,
    });
    console.log("Editing user:", user);
    showDrawer();
  };

  const handleDelete = async (id) => {
    try {
      await deleteUser(id);
      message.success("User deleted successfully");
      fetchUsers();
    } catch (error) {
      message.error("Failed to delete user");
    }
  };


  const handleFilterSave = async (values) => {
    console.log("Filter values: userId",userId, values);
     await updateUser(userId,values)
     fetchUsers()
     handleFilterCancel();
  };

  return (
    <AppLayout>
      <Content
        style={{
          margin: "16px",
          marginTop: "45px",
          paddingRight: "30px",
          paddingLeft: "25px",
        }}
      >
        {loading && <PageLoader />}
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: 16 }}
        >
          <Col>
            <h1 style={{ fontSize: "36px", fontFamily: "Montserrat" }}>
              Manage Users
            </h1>
          </Col>
          <Col>
            <span onClick={showDrawer}>
              <CustomButton type="primary">Add User</CustomButton>
            </span>
          </Col>
        </Row>
        <div style={{ marginBottom: "100px" }}>
          <UserTable
            onEdit={handleEdit}
            onDelete={handleDelete}
            onFilter={showFilterDrawer}
            users={users}
          />
        </div>
        <Drawer
          title={editingUser ? "Edit User" : "Add User"}
          onClose={handleCancel}
          visible={isDrawerVisible}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSave}
            style={{ paddingRight: "33px" }}
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please input the name" }]}
            >
              <Input
                placeholder="Enter user's name"
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "8px",
                  height: "40px",
                  padding: "8px 12px",
                }}
              />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: "Please input the email" }]}
            >
              <Input
                placeholder="Enter user's email"
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "8px",
                  height: "40px",
                  padding: "8px 12px",
                }}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[{ required: true, message: "Please input the password" }]}
            >
              <Input.Password
                placeholder="Enter user's password"
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "8px",
                  height: "40px",
                  padding: "8px 12px",
                }}
              />
            </Form.Item>
            {editingUser?.airTableType?.toLowerCase() !== "admin" && (
              <Form.Item
                name="unitNumber"
                label="Unit Access"
                rules={[
                  { required: true, message: "Please select the unit number" },
                ]}
              >
                <CustomDropdown
                  placeholder="Select unit number"
                  options={[
                    { label: "611/613", value: "appkscyvJutKmwgNB" },
                    { label: "723/725", value: "appn8T3UykPjpTnV2" },
                  ]}
                  value={form.getFieldValue("unitNumber")}
                  onChange={(value) =>
                    form.setFieldsValue({ unitNumber: value })
                  }
                />
              </Form.Item>
            )}
            <Form.Item
              name="airTableType"
              label="Role"
              rules={[
                { required: true, message: "Please select the user type" },
              ]}
            >
              <CustomDropdown
                placeholder="Select user type"
                options={[
                  { label: "Admin", value: "Admin" },
                  { label: "Owner", value: "Owner" },
                ]}
                value={form.getFieldValue("airTableType")}
                onChange={(value) =>
                  form.setFieldsValue({ airTableType: value })
                }
              />
            </Form.Item>
            <Row gutter={16}>
              <Col span={12}>
                <Button
                  onClick={handleCancel}
                  block
                  style={{ height: "40px", borderRadius: "8px" }}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  onClick={() => form.submit()}
                  type="primary"
                  block
                  style={{
                    height: "40px",
                    borderRadius: "8px",
                    backgroundColor: "#ffd936 !important",
                    color: "black",
                    border: "none",
                  }}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Drawer>

        <Drawer
          title="Filters"
          onClose={handleFilterCancel}
          visible={isFilterDrawerVisible}
        >
          <Form
            form={filterForm}
            layout="vertical"
            onFinish={handleFilterSave}
            style={{ paddingRight: "33px" }}
          >
            <Form.Item
              name="name"
              label="Name"
              rules={[{ required: true, message: "Please input the name" }]}
            >
              <Input
                placeholder="Enter user's name"
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "8px",
                  height: "40px",
                  padding: "8px 12px",
                }}
                disabled
              />
            </Form.Item>
            <Form.Item
              name="email"
              label="Email"
              rules={[{ required: true, message: "Please input the email" }]}
            >
              <Input
                placeholder="Enter user's email"
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "8px",
                  height: "40px",
                  padding: "8px 12px",
                }}
                disabled
              />
            </Form.Item>
            <Form.Item
              name="months"
              label="Months"
              rules={[{ required: true, message: "Please select the months" }]}
            >

              <Select
                placeholder="Select months"
                options={[
                  { label: "January 2021", value: "January 2021" },
                  { label: "February 2022", value: "February 2022" },
                  { label: "July 2023", value: "July 2023" },
                ]}
                mode="multiple"
              />
            </Form.Item>
            <Form.Item
              name="years"
              label="Years"
              rules={[{ required: true, message: "Please select the years" }]}
            >


              <Select
                placeholder="Select years"
                options={[
                  { label: "2019", value: "2019" },
                  { label: "2020", value: "2020" },
                  { label: "2021", value: "2021" },
                  { label: "2022", value: "2022" },
                  { label: "2024", value: "2024" },
                ]}
                mode="multiple"
              />
            </Form.Item>
            <Row gutter={16}>
              <Col span={12}>
                <Button
                  onClick={handleFilterCancel}
                  block
                  style={{ height: "40px", borderRadius: "8px" }}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  onClick={() => filterForm.submit()}
                  type="primary"
                  block
                  style={{
                    height: "40px",
                    borderRadius: "8px",
                    backgroundColor: "#ffd936 !important",
                    color: "black",
                    border: "none",
                  }}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Drawer>
      </Content>
    </AppLayout>
  );
};

export default ManageUsers;
