import Airtable from "airtable";
import { requestCompleted, requestStart } from "..";

const fatchBase = () => {
  if (localStorage.getItem("apiKey") || localStorage.getItem("baseId")) {
    let storeBase = new Airtable({
      apiKey: localStorage.getItem("apiKey"),
    }).base(localStorage.getItem("baseId"));
    return storeBase;
  }
};

// Delay function to control request rate
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

async function fetchData(base, year) {
  const storeBase = await fatchBase();
  base = base ? base : storeBase;
  return new Promise((resolve, reject) => {
    const data = [];

    base("Monthly Revenue")
      .select({
        filterByFormula: `YEAR({⁤         Month Year}) = ${year}`,
        // fields: ["monthID", "⁤         Month Year"]
      })
      .eachPage(
        async function page(records, fetchNextPage) {
          records.forEach(function (record) {
            data.push(record.fields);
          });
          await delay(200); // Delay to avoid hitting rate limit
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error(err);
            reject(err);
            return;
          }
          resolve(data);
        }
      );
  });
}

async function fetchingMonthlyData(base, year, dispatch) {
  const storeBase = await fatchBase();
  base = base ? base : storeBase;
  dispatch(requestStart());
  const data = await fetchData(base, year);
  dispatch(requestCompleted());

  return data;
}

async function fetchDataMonth(base, month) {
  const storeBase = await fatchBase();
  base = base ? base : storeBase;
  return new Promise((resolve, reject) => {
    const filterFormula = `{monthID} = '${month}'`;

    base("Monthly Revenue")
      .select({
        filterByFormula: filterFormula,
      })
      .firstPage(function (err, records) {
        if (err) {
          console.error(err);
          reject(err);
          return;
        }
        const data = records.map((record) => record.fields);
        resolve(data);
      });
  });
}

async function fetchDataBilling(base, month) {
  const storeBase = await fatchBase();
  base = base ? base : storeBase;
  return new Promise((resolve, reject) => {
    let allRecords = [];
    const filterFormula = `{monthID} = '${month}'`;

    base("Booking Revenue")
      .select({
        filterByFormula: filterFormula,
      })
      .eachPage(
        async function page(records, fetchNextPage) {
          try {
            records.forEach(function (record) {
              allRecords.push({ ...record.fields, id: record.id });
            });
            await delay(200); // Delay to avoid hitting rate limit
            fetchNextPage();
          } catch (err) {
            console.error(err);
            reject(err);
          }
        },
        function done(err) {
          if (err) {
            console.error(err);
            reject(err);
          } else {
            resolve(allRecords);
          }
        }
      );
  });
}

export { fetchDataBilling, fetchDataMonth };

export default fetchingMonthlyData;
