import React from "react";
import { ButtonModelWrapper } from "./styles";

const ButtonModel = ({ className, children }) => {
  return (
    <ButtonModelWrapper className={className}>{children}</ButtonModelWrapper>
  );
};

export default ButtonModel;
