import { useState } from "react";
import { Form, Input, Button, Row, Col, Modal } from "antd";
import { LoginCard, FormWrapper, ModalWrapper } from "./styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ChangePasswordAction } from "redux/app/Actions/Auth";
import { loading as stateLoading } from "redux/app";
import PageLoader from "Components/Shared/PageSpinner";
import { notification } from "antd";
import check from "Assets/Images/check.png";

const ChangePassword = () => {
  const navigate = useNavigate();
  const loading = useSelector(stateLoading);
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    navigate("/dashboard");
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const onFinish = (values) => {
    const { currentPassword, newPassword, confirmPassword } = values;
    if (newPassword !== confirmPassword) {
      notification.error({
        message: "The new passwords entered do not match",
      });
    } else {
      dispatch(
        ChangePasswordAction(navigate, showModal, {
          email: localStorage.getItem("user_email"),
          currentPassword,
          newPassword,
        })
      );
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div style={{ marginTop: "55px" }}>
      {loading && <PageLoader />}
      <LoginCard>
        <Row>
          <Col span={24}>
            <div
              style={{
                textAlign: "center",
                fontFamily: "Montserrat",
                fontSize: "40px",
                fontWeight: "500",
                lineHeight: "40px",
                textAlign: "left",
                top: "81px",
                left: "300px",
                opacity: "0px",
              }}
            >
              Change Password
            </div>
          </Col>
        </Row>
        <FormWrapper>
          <Form
            style={{ width: "500px" }}
            layout="vertical"
            initialValues={{
              remember: false,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <div>
              <Form.Item
                label="Current Password"
                name="currentPassword"
                rules={[
                  {
                    required: true,
                    message: "Please enter your current password",
                  },
                ]}
              >
                <Input.Password
                  placeholder="••••••••••••••"
                  type="text"
                ></Input.Password>
              </Form.Item>
              <Form.Item
                label="New Password"
                name="newPassword"
                rules={[
                  {
                    required: true,
                    message: "Please enter new password",
                  },
                ]}
              >
                <Input.Password placeholder="••••••••••••••"></Input.Password>
              </Form.Item>
              <Form.Item
                label="Confirm Password"
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your new password",
                  },
                ]}
              >
                <Input.Password placeholder="••••••••••••••"></Input.Password>
              </Form.Item>
              <div className="butn-holder">
                <button
                  className="cancel-btn"
                  onClick={handleCancel}
                  type="button"
                  loading={loading}
                >
                  Cancel
                </button>{" "}
                <Button
                  className="submit-btn"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Form>
        </FormWrapper>

        <Modal title={null} closable={false} footer={null} open={isModalOpen}>
          <Row>
            <span
              style={{
                position: "relative",
                padding: "20px",
                top: "5px",
                left: " 40%",
                borderRadius: "50px",
                opacity: "0px",
                background: "rgba(253, 250, 231, 1)",
              }}
            >
              <img src={check} />
            </span>
            <Col span={24}>
              <ModalWrapper>
                <div
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "22px",
                    fontWeight: "600",
                    lineHeight: "22.49px",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  <h5>
                    Password Updated
                    <br />
                    Successfully
                  </h5>

                  <div className="butn">
                    <Button
                      className="login-btn"
                      onClick={handleCancel}
                      loading={loading}
                      type="primary"
                    >
                      Done
                    </Button>
                  </div>
                </div>
              </ModalWrapper>
            </Col>
          </Row>
        </Modal>
      </LoginCard>
    </div>
  );
};

export default ChangePassword;
