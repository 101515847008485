import { ModelSecondaryCardWraper } from "./styles";

const ModelSecondaryCard = ({ title, value }) => {
  const displayValue = value === "-" ? "0" : value;

  return (
    <ModelSecondaryCardWraper>
      <div className="card-title">{title}</div>
      <div className="card-value">{displayValue || "0"}</div>
    </ModelSecondaryCardWraper>
  );
};

export default ModelSecondaryCard;
