import { Button } from "antd";
import { Link } from "react-router-dom";
import { ButtonWrapper } from "./styles";

const CustomButton = ({ children, type = "primary", to }) => {
  const buttonClass =
    type === "primary" ? "primary-button" : "secondary-button";

  return (
    <ButtonWrapper>
      {/* You can use a Link or any other routing component here */}
      <Link to={to}>
        <Button className={`ant-btn ${buttonClass}`}>{children}</Button>
      </Link>
    </ButtonWrapper>
  );
};

export default CustomButton;
