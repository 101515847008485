import * as React from "react";
const MonthlyOverview = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M4.3 4.7C2.4 6.5 2 8 2 12.8c0 8.1 1.2 9.5 9.3 10 8.7.6 10.7-1.2 10.7-9.7 0-8.3-1.8-10.3-9.6-10.5-4.9-.1-6.3.2-8.1 2.1zm14.5.6c.7.5 1.2 3 1.2 6.3V17H4v-5.3c0-2.9.5-5.8 1-6.3 1.3-1.3 11.9-1.3 13.8-.1zm1 13.9c-.3.9-2.8 1.3-7.8 1.3s-7.5-.4-7.7-1.3C3.9 18.3 5.9 18 12 18s8.1.3 7.8 1.2z" />
    <path d="M10.7 8.6c-.4.4-.7 2-.7 3.6 0 2.2.5 2.8 2.1 2.8 1.7 0 2-.5 1.7-3.2-.3-3.1-1.7-4.5-3.1-3.2zM13 13c0 .5-.4 1-1 1-.5 0-1-.5-1-1 0-.6.5-1 1-1 .6 0 1 .4 1 1z" />
  </svg>
);
export default MonthlyOverview;
