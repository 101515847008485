import React, { useState, useEffect } from "react";
import { DownOutlined } from "@ant-design/icons";
import "./styles.css";

const CustomDropdown = ({
  options,
  placeholder,
  onChange,
  value = [],
  mode,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    console.log("CustomDropdown - Initial value:", value);
    if (mode === "multiple" && Array.isArray(value)) {
      const selectedOptions = options.filter((option) =>
        value.includes(option.value)
      );
      setSelectedValues(selectedOptions.map((option) => option.label));
    } else {
      const selectedOption = options.find(
        (option) =>
          option.value &&
          typeof option.value === "string" &&
          option.value.toLowerCase() === String(value).toLowerCase()
      );
      setSelectedValues(selectedOption ? [selectedOption.label] : []);
    }
  }, [value, options, mode]);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    if (mode === "multiple") {
      const newSelectedValues = [...selectedValues];
      const index = newSelectedValues.indexOf(option.label);

      if (index > -1) {
        newSelectedValues.splice(index, 1);
      } else {
        newSelectedValues.push(option.label);
      }

      setSelectedValues(newSelectedValues);
      onChange(
        newSelectedValues.map(
          (label) => options.find((opt) => opt.label === label).value
        )
      );
    } else {
      setSelectedValues([option.label]);
      onChange(option.value);
      setIsOpen(false);
    }
  };

  return (
    <div className="custom-dropdown">
      <div
        className="custom-dropdown__selected"
        onClick={handleToggle}
        style={{ borderRadius: "8px", height: "40px", padding: "8px 12px" }}
      >
        {selectedValues.join(", ") || placeholder}
        <span className="custom-dropdown__arrow">
          <DownOutlined />
        </span>
      </div>
      {isOpen && (
        <l className="custom-dropdown__options">
          {options.map((option) => (
            <li
              key={option.value}
              className={`custom-dropdown__option ${
                selectedValues.includes(option.label) ? "selected" : ""
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </li>
          ))}
        </l>
      )}
    </div>
  );
};

export default CustomDropdown;
