import formattedAmount from "util/formattedString";

export const data = (val) => {
  return [
    {
      key: 1,
      card: [
        {
          cardtitle: "Guest",
          cardvalue: val?.["Guest Name"]?.replace(/[*#]/g, "").trim(),
        },
        { cardtitle: "Nights", cardvalue: val?.["Nights"] ?? "-" },
        {
          cardtitle: "Unit Number",
          cardvalue: val?.["Unit No"] ?? "-",
        },
        {
          cardtitle: "Year",
          cardvalue: val?.["Year"]?.replace(/[*#]/g, "").trim(),
        },

        {
          cardtitle: "Total dates reserved",
          cardvalue: val?.["initialDatesRsrvd"] ?? "-",
        },
      ],
    },
    {
      key: 3,
      card: [
        {
          cardtitle: "Booked Revenue",
          cardvalue:
            val?.["Booked Revenue"] !== 0
              ? formattedAmount(val?.["Booked Revenue"], false)
              : "-",
        },
        {
          cardtitle: "Lodging Tax",
          cardvalue: formattedAmount(val?.["Lodging Tax"], false),
        },
        {
          cardtitle: "Net of Taxes",
          cardvalue: formattedAmount(val?.["Net of Taxes"], false),
        },
        {
          cardtitle: "Owner revenue",
          cardvalue: formattedAmount(val?.["Owner Revenue ✔️"], false),
        },
      ],
    },
  ];
};
