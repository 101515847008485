import styled from "styled-components";

export const UserinfoWrapper = styled.div`
  // margin: 56px 0px 40px 0px;
  padding: 0px 46px 10px 20px;
  .user-info-user-name {
    font-size: 14px;
    font-weight: 400;
    font-family: "poppins";
  }
  .user-info-address {
    font-size: 13px;
  }
`;
