function generateYearsUntil2017() {
  const currentYear = new Date().getFullYear();
  const targetYear = 2017;
  const yearsArray = [];

  for (let year = currentYear; year >= targetYear; year--) {
    yearsArray.push(year);
  }

  return yearsArray;
}

const years = generateYearsUntil2017();
export default years;
