import React from "react";
import { MonthlyOverviewModal, MonthlyOverviewWrapper } from "./styles";
import { Checkbox, Col, Row } from "antd";
import ModelDataCard from "Components/Shared/ModelDataCard";
import { data as getData } from "./data";
import ModelSecondaryCard from "Components/Shared/ModelSecondaryCard";
import Frame from "Assets/Icons/Frame";
import SubHeading from "Components/Shared/SubHeading";
import formattedAmount from "util/formattedString";
import ModelNavigation from "Components/Shared/ModelNavigation";
import CheckIcon from "Assets/Icons/CheckIcon";

const MonthlyOverview = ({ rowData, visible, onClose }) => {
  const data = getData(rowData);
  const monthlyRentalIncomeData = data[1];

  return (
    <MonthlyOverviewModal
      visible={visible}
      onCancel={onClose}
      footer={null}
      mask={false}
    >
      <MonthlyOverviewWrapper>
        <ModelNavigation onCloseModal={onClose} />

        <Row>
          <div className="model-title">{rowData?.["Dates Occupied"]}</div>
        </Row>

        {data.map((item, index) => (
          <div key={item.key}>
            {/* Render for first two indexed objects */}
            {index !== 1 && (
              <>
                <Row className="card-holder">
                  {item.card.map((cardItem, cardIndex) => (
                    <Col md={12}>
                      <ModelDataCard
                        key={cardIndex}
                        title={cardItem.cardtitle}
                        value={cardItem.cardvalue}
                      />
                    </Col>
                  ))}
                </Row>
              </>
            )}
          </div>
        ))}

        <div className="rentel-income-card-holder">
          <SubHeading className="SubHeading">Rental Income</SubHeading>
          {rowData?.["Owner  Occupied"] ? (
            <Row>
              <div className="owner-occupied-row">
                <div className="card-title">Owner  Occupied</div>
                <div className="card-title">Value of owner booking</div>
              </div>
              <Row className="owner-occupied-2nd-row">
                <div className="frame-holder">
                  {rowData?.["Owner  Occupied"] ? <CheckIcon /> : <Frame />}
                </div>

                <div className="card-value">
                  {formattedAmount(rowData?.["Owner Booking Value"], true)}
                </div>
              </Row>
            </Row>
          ) : (
            <Row>
              {monthlyRentalIncomeData.card.map((cardItem, cardIndex) => (
                <Col xl={12}>
                  <ModelSecondaryCard
                    key={cardIndex}
                    title={cardItem.cardtitle}
                    value={cardItem.cardvalue}
                  />
                </Col>
              ))}
            </Row>
          )}
        </div>
      </MonthlyOverviewWrapper>
    </MonthlyOverviewModal>
  );
};

export default MonthlyOverview;
