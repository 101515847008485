import styled from 'styled-components';

export const TableWrapper = styled.div`
  padding: 0px 46px 0px 20px;
  margin-bottom: 72px;

  .recap-table-heading {
    margin: 24px 0px 24px 0px !important;
    font-size: 24px;
    font-weight: 600;
  }

  .content-table-container {
    margin-top: 20px;
  }

  .content-table-container .ant-table {
    border: none;
  }

  .content-table-container .ant-table-thead > tr > th {
    background: #fafafa;
    font-weight: 500;
    font-family: 'Poppins' !important;
    border-bottom: 1px solid #e7e7e7;
    font-size: 13px;
  }

  .content-table-container .ant-table-tbody > tr > td {
    background: transparent !important;
    border-bottom: 1px solid #e7e7e7;
    padding: 12px 16px !important;
    font-size: 13px;
    font-weight: 400;
    line-height: 14px;
  }

  .content-table-container .ant-table-tbody > tr > .boldColumn.ant-table-cell {
    font-weight: 600;
  }

  .content-table-container .ant-table-tbody > tr:hover > td {
    background-color: #fdfae7 !important;
  }

  .content-table-container .ant-table-tbody > tr:active > td {
    background-color: #fdfae7 !important;
  }

  .content-table-container .table-content-tick-icon {
    font-size: 14px !important;
    line-height: 0px !important;
    color: rgba(0, 0, 0, 0.85) !important;
  }

  .table-content-tick-icon {
    line-height: 0px !important;
  }

  .selected-row {
    background-color: #fdfae7 !important; /* Set your desired background color */
  }

  .ant-tooltip .ant-tooltip-inner {
    color: rgb(0, 0, 0) !important;
    background-color: rgb(255, 255, 255) !important;
    width: 750px !important;
    font-size: 13px;
    font-weight: 500;
    font-style: italic;
    font-family: 'Montserrat';
    text-align: center !important;
    padding: 10px 10px 20px 10px !important;
    border-radius: 8px !important;
  }

  .ant-tooltip .ant-tooltip-arrow-content::before {
    background: #fff;
  }

  .empty-indicator {
    font-weight: bold;
    margin-left: 50px !important;
    font-size: 8px;
    line-height: 16px;
    color: black;
  }
`;
