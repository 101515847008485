import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Spinner, SpinnerWrapper } from "./styles";

const AntIcon = (
  <SpinnerWrapper>
    <LoadingOutlined spin />
  </SpinnerWrapper>
);

const PageLoader = () => (
  <Spinner>
    <Spin size="large" />
  </Spinner>
);

export default PageLoader;
