import { useState } from "react";
import { Form, Input, Button, Row, Col, Typography, notification } from "antd";
import { LoginCard, LoginWrapper, FormWrapper } from "./styles";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ForgetPasswordAction } from "redux/app/Actions/Auth";
import { loading as stateLoading } from "redux/app";
import { LoginHeading } from "./styles";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const loading = useSelector(stateLoading);
  const dispatch = useDispatch();
  const [email, setEmail] = useState();

  const handleForgetPassword = () => {
    if(email) {
      dispatch(ForgetPasswordAction(navigate, { email: email }));
    }else {
      notification.error({
        message: 'Please enter your email',
      });
    }
  };

  return (
    <LoginWrapper>
      <LoginCard>
        <Row>
          <Col span={24}>
            <div
              style={{
                textAlign: "center",
                fontFamily: "Montserrat",
                fontSize: "22px",
                fontWeight: "900",
                lineHeight: "18.49px",
                textAlign: "center",
              }}
            >
              <LoginHeading>Enter your email</LoginHeading>
            </div>
          </Col>
        </Row>
        <Row justify={"center"}>
          <Col span={24}>
            <div className="login-paragraph">
              <p>
                Please enter the email address associated with your Account. We
                will send you a link to reset your password.
              </p>
            </div>
          </Col>
        </Row>
        <FormWrapper>
          <Form.Item wrapperCol={{ span: 24 }}>
            <div>
              <div className="login-email-wrapper">
                <Typography.Title level={5}>Email Address</Typography.Title>
                <Input
                  type="text"
                  placeholder="bookluxury@gmail.com"
                  onChange={(e) => setEmail(e.target.value)}
                ></Input>
              </div>
              <Button
                className="login-btn"
                onClick={handleForgetPassword}
                type="primary"
                loading={loading}
              >
                Submit
              </Button>
            </div>
          </Form.Item>
        </FormWrapper>
      </LoginCard>
    </LoginWrapper>
  );
};

export default ForgetPassword;
