import { useState } from "react";
import { Form, Button, Row, Col, Typography } from "antd";
import { LoginCard, LoginWrapper, LoginHeading } from "./styles";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { loading as stateLoading } from "redux/app";
import PageLoader from "Components/Shared/PageSpinner";
import check from "Assets/Images/check.png";

const AuthNotification = () => {
  const navigate = useNavigate();
  const [passwordUpdate] = useState(
    JSON.parse(localStorage.getItem("passwordUpdate"))
  );
  const loading = useSelector(stateLoading);
  console.log(passwordUpdate, "passwordUpdate");

  return (
    <LoginWrapper>
      {loading && <PageLoader />}
      <LoginCard>
        {!passwordUpdate ? (
          <span>
            <Row>
              <span className="check-svg">
                <img src={check} />
              </span>
              <Col span={24}>
                <div
                  style={{
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    fontSize: "30px",
                    fontWeight: 900,
                    lineHeight: "18.49px",
                    marginTop: "30px",
                  }}
                >
                  <LoginHeading>
                    <h5 className="login-heading">Email sent!</h5>
                  </LoginHeading>
                </div>
              </Col>
            </Row>
            <h5 className="forget-paragraph">
              Please check your inbox for an email with a link to reset your
              password.
            </h5>

            <button
              className="login-btn"
              onClick={() => navigate("/login")}
              type="primary"
              loading={loading}
              htmlType="submit"
            >
              Login
            </button>
          </span>
        ) : (
          <span>
            <Row>
              <span className="check-svg">
                <img src={check} />
              </span>
              <Col span={24}>
                <div
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "22px",
                    fontWeight: "600",
                    lineHeight: "22.49px",
                    textAlign: "center",
                    marginTop: "15px",
                  }}
                >
                  <LoginHeading>
                    <h5 className="login-heading">
                      Password Updated
                      <br />
                      Successfully
                    </h5>
                  </LoginHeading>
                </div>
              </Col>
            </Row>
            <Button
              className="login-btn"
              onClick={() => {
                navigate("/login");
                localStorage.setItem("passwordUpdate", false);
              }}
              type="primary"
              loading={loading}
            >
              Login
            </Button>
          </span>
        )}
      </LoginCard>
    </LoginWrapper>
  );
};

export default AuthNotification;
