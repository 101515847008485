import { Row, Col } from "antd";
import { data } from "./data";
import CloseIcon from "Assets/Icons/CloseIcon";
import Print from "Assets/Icons/Print";

const ModelNavigation = ({ onCloseModal }) => {
  const handlePrintClick = () => {
    window.print();
  };
  return (
    <Row justify={"end"}>
      {data(onCloseModal, handlePrintClick).map((item, index) => (
        <Col key={index} xl={1}>
          <div style={{ cursor: "pointer" }} onClick={item.onClick}>
            <item.Icon />
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default ModelNavigation;
