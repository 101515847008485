import SubHeading from "Components/Shared/SubHeading";
import TableContent from "./TableContent";
import "./styles.js";
import { TableWrapper } from "./styles.js";

const RecapTable = (props) => {
  return (
    <TableWrapper>
      <SubHeading className="SectionHeading">Booking Entries</SubHeading>
      <TableContent {...props} />
    </TableWrapper>
  );
};

export default RecapTable;
