import SingleCard from "./SingleCard";
import "../summary.css";
import formattedAmount from "util/formattedString";
import getPercentValue from "util/getPercentage";

const FirstRow = ({ data }) => {
  return (
    <div className="summary-first-row">
      <SingleCard
        value={formattedAmount(data?.["Gross Revenue"], true)}
        title="Gross Revenue"
      />
      <SingleCard
        value={formattedAmount(data?.["Net of Taxes"], true)}
        title="Net of Taxes"
      />
      <SingleCard
        value={formattedAmount(data?.["Revenue Split  ( 𝟩𝟢﹪)"], true)}
        title="Revenue Split"
      />
      <SingleCard
        value={data?.["Occupancy Rate"] ? getPercentValue(data?.["Occupancy Rate"]) : '-'}
        title="Occupancy Rate"
      />
    </div>
  );
};

export default FirstRow;
