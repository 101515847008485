import styled from "styled-components";
import { Modal } from "antd";

export const MonthlyOverviewWrapper = styled.div`
  padding: 28px 0px;
  display: flex;
  flex-direction: column;
  &.center {
    text-align: center;
  }
  .cards-holder {
    padding: 20px;
    display: flex;
    flex-direction: row;
    gap: 0px 8px;
    border-radius: 8px;
    margin-bottom: 20px;
    background: #fafafa;
    border: 1px solid #e7e7e7;
  }
  .card-holder {
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    background: #fafafa;
    border: 1px solid #e7e7e7;
  }
`;

export const MonthlyOverviewModal = styled(Modal)`
  && {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    min-width: 720px;
  }

  .ant-modal-content {
    height: 120vh;
    min-width: 720px;
    width: 100%;
    border-radius: 0;
    padding:0px 44px;
    overflow:scroll;
  }
  .ant-modal-body {
    padding: 0px 0px 0px 0px;
  }
  .ant-modal-close-x {
    display: none;
  }

  .green-circle {
    padding: 3px 4px;
    width: fit-content;
  }
  .butn-holder{
    display:flex;
    justify-content:end;
    align-items-end;
    height:100%;
  }
  .owner-rev-card{
    padding: 19px 12px !important;
    background: #fafafa;
    border-radius: 8px;
    border: 1px solid transparent;
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-direction:row;
    gap:66px;
    margin-top:22px;
    width:100%;
  }
  .owner-occupied-card{
    width:100%;
    margin-top:20px;
    border-radius:8px;
    background: #fafafa;
    border: 1px solid #e7e7e7;
  }
 .owner-occupied-row{
  padding: 19px 0px 16px!important;
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-direction:row;
  gap:66px;
  width:100%;
  }
  .frame-holder{
    margin-right:63px;
  }
  .owner-occupied-2nd-row{
    padding: 0px 0px 13px!important;
    align-items: center;
    display: flex;
    flex-grow: 1;
    flex-direction:row;
    gap:100px;
    width:100%;
  }
  .card-title {
    color: ${(props) => props.theme.colors.black};
    font-family: "Montserrat";
    font-size: 13px;
    line-height: 24px;
    font-weight: 600;
  }
  .card-value {
    color: ${(props) => props.theme.colors.black};
    font-family: "Montserrat";
    font-size: 24px;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;

  }
  .model-title {
    width: 100%;
    padding-bottom: 24px;
    margin-bottom: 16px;
    font-size: 40px;
    line-height:40px;
    font-weight: 500;
    font-family: "Montserrat";
    color: #110f0e;
    border-bottom: 1px solid #cccccc;
    margin-top:40px;
  }
  .rentel-income-card-holder{
border-radius:8px;
  padding:20px;
    background: #fafafa;
    border:1px solid #E7E7E7;
    .card-value{
      width:50%;
    }
  }
  .full-width-card{
    width:100%;
    margin-bottom:40px;
    .card-title{
      margin-bottom:12px;
    }
  }
//   .ant-checkbox-checked .ant-checkbox-inner {
//     background-color: #ffffff;
//     border-color: #000000;
//     }
//  .ant-checkbox-wrapper .ant-checkbox-inner::after {
//   content: url('../../../../Assets/Images/CheckIcon.png');
//     }
  
`;
