export default function PreviousIcon() {
  return (
    <svg
      width="5"
      height="9"
      viewBox="0 0 5 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.91016 7.74714L1.23693 5.07392C0.921231 4.75822 0.921231 4.24161 1.23693 3.92591L3.91016 1.25269"
        stroke="#110F0E"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
