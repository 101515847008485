import React from "react";
import { MonthlyPreviewModal, PreviewModelWrapper } from "./styles";
import SubHeading from "Components/Shared/SubHeading";
import { Col, Row } from "antd";
import ModelDataCard from "Components/Shared/ModelDataCard";
import { data } from "./data";
import ModelSecondaryCard from "Components/Shared/ModelSecondaryCard";
import ButtonModel from "Components/Shared/ButtonModel";
import LinkIcon from "Assets/Icons/LinkIcon";
import formattedAmount from "util/formattedString";
import { removePMonthYear } from "util/get3MonthYear";
import ModelNavigation from "Components/Shared/ModelNavigation";
import { changeSelectedMonth } from "redux/app/reducers/tabsSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
const MonthlyPreview = ({ rawData, visible, onClose, setMenu }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMonthlyPreview = () => {
    console.log("Raw Data => ", rawData);
    dispatch(changeSelectedMonth(rawData?.monthID));
    navigate("/monthly-statement");
  };

  return (
    <MonthlyPreviewModal
      visible={visible}
      onCancel={onClose}
      footer={null}
      mask={false}
    >
      <PreviewModelWrapper>
        <ModelNavigation onCloseModal={onClose} />
        <Row>
          <div className="model-title">
            {removePMonthYear(rawData?.monthlyID)}
          </div>
        </Row>
        <Row
          style={{
            marginBottom: "20px",
            background: "rgb(250, 250, 250)",
            borderRadius: "8px",
            padding: "20px",
            border: "1px solid #E7E7E7",
          }}
        >
          <Col md={12}>
            <SubHeading className="SubHeading">Rental Income</SubHeading>
            <div className="black-line"></div>
          </Col>
          <Col xl={12}>
            <div className="butn-holder" onClick={handleMonthlyPreview}>
              <ButtonModel>
                View monthly statement{" "}
                <div className="link-icon">
                  <LinkIcon />
                </div>
              </ButtonModel>
            </div>
          </Col>
          <Col md={12}>
            <ModelSecondaryCard
              key="Gross"
              title="Gross revenue"
              value={formattedAmount(rawData?.["Monthly Revenue"], true)}
            />
          </Col>
          {rawData?.["Monthly Revenue"] ? (
            <>
              <Col md={12}>
                <ModelSecondaryCard
                  key="r_split"
                  title="Lodging tax"
                  value={formattedAmount(rawData?.["Lodging Tax"], true)}
                />
              </Col>
              <Col md={12}>
                <ModelSecondaryCard
                  key="Net_Taxes"
                  title="Net of taxes"
                  value={formattedAmount(rawData?.["Net of Taxes"], true)}
                />
              </Col>
              <Col md={12}>
                <ModelSecondaryCard
                  key="r_split"
                  title="Owner revenue"
                  value={formattedAmount(
                    rawData?.["Revenue Split  ( 𝟩𝟢﹪)"],
                    true
                  )}
                />
              </Col>
            </>
          ) : (
            ""
          )}
        </Row>

        {data(rawData).map((item, index) => (
          <div key={item.key}>
            {index !== 2 && (
              <>
                <div className="model-data-row">
                  <SubHeading className="SubHeading">{item.title}</SubHeading>
                  <div className="black-line"></div>

                  <Row className="cards-holder">
                    {item.card.map((cardItem, cardIndex) => (
                      <Col md={11}>
                        <ModelDataCard
                          key={cardIndex}
                          title={cardItem.cardtitle}
                          value={cardItem.cardvalue}
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              </>
            )}
          </div>
        ))}

      </PreviewModelWrapper>
    </MonthlyPreviewModal>
  );
};

export default MonthlyPreview;
