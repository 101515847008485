import logo from "Assets/Images/book-luxury-logo.png";
import dots from "Assets/Images/dots.png";
import m_calendar from "Assets/Images/m_calendar.png";
import calendar from "Assets/Images/calendar.png";
import users from "Assets/Images/users.png";
export const Images = {
  logo,
  dots,
  m_calendar,
  calendar,
  users,
};
