const monthNames = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];


function generateMonthYearArray() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const monthYearArray = [];

  for (let year = currentYear; year >= 2018; year--) {
    const endMonth = (year === currentYear) ? currentMonth : 11;
	
    for (let month = endMonth; month >= 0; month--) {
      monthYearArray.push(`${monthNames[month]} ${year}`);
    }
  }

  return monthYearArray;
}

const currentMonthYear = () => {
  const currentDate = new Date();
  const currentMonth = monthNames[currentDate.getMonth()];
  const currentYear = currentDate.getFullYear();
  return `${currentMonth} ${currentYear}`
}

export { currentMonthYear }
export default generateMonthYearArray;