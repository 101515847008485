import { Badge, Tooltip } from 'antd';
import { SummaryCardWraper } from './styles';
import InfoSuperscript from 'Assets/Icons/InfoSuperscript';

const SummaryCard = ({ cardid, title, value, tooltipTitile }) => {
  return (
    <SummaryCardWraper>
      <div className='summary-card-title' key={cardid}>
        {title}
        {tooltipTitile ? (
          <Tooltip
            title={
              <div style={{ textAlign: 'left', whiteSpace: 'normal' }}>
                {tooltipTitile}
              </div>
            }
            overlayStyle={{ maxWidth: '200px' }}
          >
            <Badge count={<InfoSuperscript />} />
          </Tooltip>
        ) : (
          ''
        )}
      </div>
      <div className='summary-card-value'>{value || '-'}</div>
    </SummaryCardWraper>
  );
};

export default SummaryCard;
