import styled from 'styled-components';

export const ModelDataCardWraper = styled.div`
  padding-bottom: 10px;
  background: #fafafa;
  border-width: 4px;
  border-radius: 8px;
  border: 1px solid transparent;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-width: 0;
  transition: all 0.3s ease-in-out;

  .card-title {
    color: ${(props) => props.theme.colors.black};
    font-family: 'Montserrat';
    font-size: 13px;
    line-height: 13px;
    font-weight: 400;
    margin-bottom: 12px;
    text-align: start;
  }
  .card-value {
    color: ${(props) => props.theme.colors.black};
    font-family: 'Montserrat';
    font-size: 24px;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
  }
`;
