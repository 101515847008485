import { useState, useEffect } from "react";
import BarChart from "./Chart";
import Header from "./Header";
import UserInfo from "./Header/UserInfo";
import Summary from "./Summary";
import Table from "./Table";
import { getMonthlyData } from "redux/app/url";
import { useSelector, useDispatch } from "react-redux";
import Airtable from "airtable";
import { selectedUnit, selectedYear } from "redux/app/reducers/tabsSlice";
import { apiData } from "redux/app";

const Content = ({ ...rest }) => {
  const apiKeys = useSelector(apiData);
  const [tableData, setTableData] = useState(null);
  const [barChartData, setBarChartData] = useState(null);
  const [rawMonthlyData, setRawMonthlyData] = useState(null);
  const yearSelected = useSelector(selectedYear);
  const baseId = useSelector(selectedUnit);
  const userType = localStorage.getItem("userType");

  const dispatch = useDispatch();
  useEffect(() => {
    async function fetchData(base) {
      try {
        const data = await getMonthlyData(yearSelected, base, dispatch);
        console.log("Yearly Data ", data);
        setTableData(data.tableData);
        setBarChartData(data.barChartData);
        setRawMonthlyData(data.rawData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    let base = new Airtable({
      apiKey: apiKeys.apiKay ? apiKeys.apiKay : localStorage.getItem("apiKey"),
    }).base(apiKeys.baseId ? apiKeys.baseId : localStorage.getItem("baseId"));
    fetchData(base);
  }, [yearSelected, apiData, baseId]);

  return (
    <div style={{ position: "relative" }}>
      <Header />
      {userType !== "ADMIN" && <UserInfo />}
      <Summary yearSelected={yearSelected} baseId={baseId} />
      <Table {...rest} data={tableData} rawData={rawMonthlyData} />
      <BarChart chartData={barChartData} />
    </div>
  );
};

export default Content;
