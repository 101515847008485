const abbreviatedMonthYear = (monthYear) => {
  
  if (monthYear?.includes('(p)')) monthYear = monthYear.slice(0, -4);
  
  return monthYear.substr(0, 3) + " " + monthYear.substr(-4);
}

const removePMonthYear = (monthYear) => {
  if (monthYear?.includes('(p)')) monthYear = monthYear.slice(0, -4);
  return monthYear;
}

export { removePMonthYear }

export default abbreviatedMonthYear;