import { SubHeadingWraper } from "./styles";

const SubHeading = ({ children, className }) => {
  return (
    <SubHeadingWraper>
      <h2 className={className}>{children}</h2>
    </SubHeadingWraper>
  );
};

export default SubHeading;
