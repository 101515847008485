export default function InfoCircle(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00004 0.833658C4.04671 0.833658 0.833374 4.04699 0.833374 8.00033C0.833374 11.9537 4.04671 15.167 8.00004 15.167C11.9534 15.167 15.1667 11.9537 15.1667 8.00033C15.1667 4.04699 11.9534 0.833658 8.00004 0.833658ZM8.00004 14.167C4.60004 14.167 1.83337 11.4003 1.83337 8.00033C1.83337 4.60033 4.60004 1.83366 8.00004 1.83366C11.4 1.83366 14.1667 4.60033 14.1667 8.00033C14.1667 11.4003 11.4 14.167 8.00004 14.167Z"
        fill="#110F0E"
      />
      <path
        d="M8 6.83366C7.72667 6.83366 7.5 7.06033 7.5 7.33366V10.667C7.5 10.9403 7.72667 11.167 8 11.167C8.27333 11.167 8.5 10.9403 8.5 10.667V7.33366C8.5 7.06033 8.27333 6.83366 8 6.83366Z"
        fill="#110F0E"
      />
      <path
        d="M8.00004 4.66707C7.91337 4.66707 7.82671 4.68707 7.74671 4.7204C7.66671 4.75374 7.59337 4.8004 7.52671 4.8604C7.46671 4.92707 7.42004 4.99374 7.38671 5.0804C7.35337 5.1604 7.33337 5.24707 7.33337 5.33374C7.33337 5.4204 7.35337 5.50707 7.38671 5.58707C7.42004 5.66707 7.46671 5.7404 7.52671 5.80707C7.59337 5.86707 7.66671 5.91374 7.74671 5.94707C7.90671 6.01374 8.09337 6.01374 8.25337 5.94707C8.33337 5.91374 8.40671 5.86707 8.47337 5.80707C8.53337 5.7404 8.58004 5.66707 8.61337 5.58707C8.64671 5.50707 8.66671 5.4204 8.66671 5.33374C8.66671 5.24707 8.64671 5.1604 8.61337 5.0804C8.58004 4.99374 8.53337 4.92707 8.47337 4.8604C8.40671 4.8004 8.33337 4.75374 8.25337 4.7204C8.17337 4.68707 8.08671 4.66707 8.00004 4.66707Z"
        fill="#110F0E"
      />
    </svg>
  );
}
