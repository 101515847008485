import { createSlice } from "@reduxjs/toolkit";
import { currentMonthYear } from "util/getMonths";

const initialState = {
  currentUnit: "",
  tableList: [],
  selectedYear: 2024,
  selectedMonth: currentMonthYear()
};

const tabsSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    setUnitsList(state, { payload }) {
      state.tableList = payload;
    },
    setSelectedUnit(state, {payload}) {
      state.currentUnit = payload
    },
    changeSelectedYear(state, {payload}) {
      state.selectedYear = payload
    },
    changeSelectedMonth(state, {payload}) {
      state.selectedMonth = payload
    }
  },
});

export const unitsList = (state) => state.tabs.tableList;
export const selectedUnit = (state) => state.tabs.currentUnit;
export const selectedYear = (state) => state.tabs.selectedYear;
export const selectedMonth = (state) => state.tabs.selectedMonth;

export const { setUnitsList, setSelectedUnit, changeSelectedYear, changeSelectedMonth } = tabsSlice.actions;

export default tabsSlice.reducer;
