import React, { useEffect, useState } from "react";
import { YearlyWrapper } from "./styles";
import DropDownComponent from "./yearlyDropDown";
import { generateDateOptions } from "../../../util/generateYear";
export const YearlyDropdown = () => {
  const [options, setOptions] = useState([]);
  const [baseId, setBaseId] = useState(localStorage.getItem("baseId"));

  useEffect(() => {
    const updateOptions = () => {
      const latestBaseId = localStorage.getItem("baseId");
      if (latestBaseId !== baseId) {
        console.log(`Base ID updated from local storage: ${latestBaseId}`);
        setBaseId(latestBaseId);
        const generatedOptions = generateDateOptions(latestBaseId);
        setOptions(generatedOptions);
      }
    };

    updateOptions();

    const intervalId = setInterval(updateOptions, 500);

    return () => clearInterval(intervalId);
  }, [baseId]);

  return (
    <YearlyWrapper>
      <DropDownComponent
        options={options}
        getPopupContainer={(trigger) => trigger.parentElement}
      />
    </YearlyWrapper>
  );
};
