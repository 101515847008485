import * as React from "react";
const Users = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M5.6 2.6c-2.2 2.2-2 6.1.4 7.6 2.5 1.6 2.6 2.8.1 2.8-1.1 0-2.7 1-3.5 2.2-3.2 4.5 2.8 8.9 9.6 6.9 6.1-1.9 6-6.9-.1-9.2l-2.4-1 2.1-1.5C15.7 7.7 13.7 1 9 1c-1 0-2.6.7-3.4 1.6zm6.3 2.1C13.1 7 11.5 10 9 10S4.9 7.1 6.1 4.8c1.2-2.3 4.5-2.3 5.8-.1zm1.6 10.7c1.1.8 1.6 2 1.2 2.9-1.3 3.4-10.1 3.4-11.4 0-1.4-3.5 6.3-5.8 10.2-2.9z" />
    <path d="M16 3.9c0 .5.7 1.1 1.5 1.5 2.2.8 1.8 3.7-.5 4.3-1.9.5-1.9 2.3 0 2.3 1.3 0 4-3 4-4.5S18.3 3 16.9 3c-.5 0-.9.4-.9.9zM18 13.6c0 .4.8 1.3 1.8 2 1.6 1.3 1.6 1.5-.2 2.9-1.1.8-1.7 1.8-1.3 2.1 1 1 3.7-1.6 3.7-3.5 0-.9-.5-2.2-1.2-2.9-1.3-1.3-2.8-1.6-2.8-.6z" />
  </svg>
);
export default Users;
