export default function NextIcon() {
  return (
    <svg
      width="5"
      height="9"
      viewBox="0 0 5 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7.74714L3.67322 5.07392C3.98893 4.75822 3.98893 4.24161 3.67322 3.92591L1 1.25269"
        stroke="#110F0E"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
