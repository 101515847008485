import addDollar from "./dollarSign";
import truncatedNumber from "./truncateNumber";

const formattedAmount = (amount, removeDecimal = false) => {
  if (amount === 0) return amount;
  if (!amount) return "-";
  if (removeDecimal)
    return addDollar(
      `${Math.round(amount)}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    );
  return addDollar(
    `${truncatedNumber(amount)}`?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  );
};
export default formattedAmount;
