export default function CloseIcon(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4">
        <path
          d="M7.99992 15.1663C4.04659 15.1663 0.833252 11.953 0.833252 7.99967C0.833252 4.04634 4.04659 0.833008 7.99992 0.833008C11.9533 0.833008 15.1666 4.04634 15.1666 7.99967C15.1666 11.953 11.9533 15.1663 7.99992 15.1663ZM7.99992 1.83301C4.59992 1.83301 1.83325 4.59967 1.83325 7.99967C1.83325 11.3997 4.59992 14.1663 7.99992 14.1663C11.3999 14.1663 14.1666 11.3997 14.1666 7.99967C14.1666 4.59967 11.3999 1.83301 7.99992 1.83301Z"
          fill="#110F0E"
        />
        <path
          d="M6.11332 10.3869C5.98666 10.3869 5.85999 10.3402 5.75999 10.2402C5.56666 10.0469 5.56666 9.7269 5.75999 9.53357L9.53332 5.76023C9.72666 5.5669 10.0467 5.5669 10.24 5.76023C10.4333 5.95357 10.4333 6.27357 10.24 6.4669L6.46666 10.2402C6.37332 10.3402 6.23999 10.3869 6.11332 10.3869Z"
          fill="#110F0E"
        />
        <path
          d="M9.88666 10.3869C9.75999 10.3869 9.63332 10.3402 9.53332 10.2402L5.75999 6.4669C5.56666 6.27357 5.56666 5.95357 5.75999 5.76023C5.95332 5.5669 6.27332 5.5669 6.46666 5.76023L10.24 9.53357C10.4333 9.7269 10.4333 10.0469 10.24 10.2402C10.14 10.3402 10.0133 10.3869 9.88666 10.3869Z"
          fill="#110F0E"
        />
      </g>
    </svg>
  );
}
