export default function LinkIcon(props) {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.04175 5.95924L11.4834 1.51758"
        stroke="#110F0E"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.9169 3.68203V1.08203H9.31689"
        stroke="#110F0E"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.9585 1.08203H4.87516C2.16683 1.08203 1.0835 2.16536 1.0835 4.8737V8.1237C1.0835 10.832 2.16683 11.9154 4.87516 11.9154H8.12516C10.8335 11.9154 11.9168 10.832 11.9168 8.1237V7.04036"
        stroke="#110F0E"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
