import { createGlobalStyle } from "styled-components";
import NunitoSansRegular from "Assets/Fonts/NunitoSans/NunitoSansRegular.woff";
export const GlobalStyles = createGlobalStyle`
@font-face {
  font-family: 'Nunito Sans';
  src:  url(${NunitoSansRegular}) format('woff');
  font-weight: 400;
  font-style: normal;
}
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right:none;
}
.custom-menu li.ant-menu-item:hover {
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  font-size:13px;
  font-weight:400;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  font-weight:400;
}
.ant-menu-vertical .ant-menu-item{
  margin-top:0px;
}
.ant-menu-title-content {
line-height: 0px;
    margin-top: 3px;
}

//////////////////////////////////////////////////// ant table////////////////////////////////////////////////////////


.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
display:none;
}
.ant-table-thead > tr > th {
  border-bottom:1px solid #E7E7E7 ;
}
.ant-table > tr {
  border-bottom:1px solid #E7E7E7 ;
}
.ant-table-row:hover {
  background:#FDFAE7;
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius:8px;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 8px;
}
.ant-table-container table > tbody > tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}
.ant-table-container table > tbody > tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}
.ant-table-container table > tbody > tr:hover {
  background:#FDFAE7;
}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
font-size:13px !important;
font-weight:500 !important;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding:12px;
  line-height:23px;
}


// // // ant modal // // //
.ant-modal-content {
  height: 100vh !important;
}
`;
