import styled from "styled-components";
export const SpinnerWrapper = styled.div`
  font-size: 100px;
  color: #ffc704;
`;

export const Spinner = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 9999;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 260px;
  left: 50%;
  transform: translate(-50%, -50%);
`
