import formattedAmount from "util/formattedString";

export const data = (vs) => [
  {
    key: 1,
    title: "Guest Bookings",
    card: [
      { cardtitle: "Total bookings", cardvalue: vs?.["Guest Bookings"] || "0" },
      ...(vs?.["Guest Bookings"]
        ? [
            {
              cardtitle: "Total nights",
              cardvalue: vs?.["Guest Nights"],
            },
            {
              cardtitle: "Rate per booking",
              cardvalue:
                formattedAmount(vs?.["Rate per Guest Booking"], true) || "-",
            },
            {
              cardtitle: "Rate per night",
              cardvalue:
                formattedAmount(vs?.["Guest Nightly Rate"], true) || "-",
            },
          ]
        : []),
    ],
  },
  {
    key: 2,
    title: "Owner Bookings",
    card: [
      { cardtitle: "Total bookings", cardvalue: vs?.["OwnerBookings"] || "0" },
      ...(vs?.["OwnerBookings"]
        ? [
            {
              cardtitle: "Total nights",
              cardvalue: vs?.["Owner Nights"],
            },
            {
              cardtitle: "Booking value(s)",
              cardvalue: formattedAmount(vs?.["Val of oBookings"], true) || "-",
            },
            {
              cardtitle: "Value per night",
              cardvalue:
                formattedAmount(vs?.["Owner Nightly Rate"], true) || "-",
            },
          ]
        : []),
    ],
  },
  {
    key: 3,
    title: "Monthly Rental Income",
    card: [
      {
        cardtitle: "Gross Revenue",
        cardvalue: formattedAmount(vs?.["MonthlyRevenue"], true) || "-",
      },
      {
        cardtitle: "Net of Taxes",
        cardvalue: formattedAmount(vs?.["Net of Taxes"], true) || "-",
      },
      {
        cardtitle: "Revenue Split (70%)",
        cardvalue: formattedAmount(vs?.["Revenue Split  ( 𝟩𝟢﹪)"], true) || "-",
      },
    ],
  },
];
