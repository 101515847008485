export default function Print(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4">
        <path
          d="M11.1666 5.16634H4.83325C4.55992 5.16634 4.33325 4.93967 4.33325 4.66634V3.33301C4.33325 1.62634 5.12659 0.833008 6.83325 0.833008H9.16659C10.8733 0.833008 11.6666 1.62634 11.6666 3.33301V4.66634C11.6666 4.93967 11.4399 5.16634 11.1666 5.16634ZM5.33325 4.16634H10.6666V3.33301C10.6666 2.19967 10.2999 1.83301 9.16659 1.83301H6.83325C5.69992 1.83301 5.33325 2.19967 5.33325 3.33301V4.16634Z"
          fill="#110F0E"
        />
        <path
          d="M8.66659 15.1667H7.33325C5.71992 15.1667 4.83325 14.28 4.83325 12.6667V10C4.83325 9.72667 5.05992 9.5 5.33325 9.5H10.6666C10.9399 9.5 11.1666 9.72667 11.1666 10V12.6667C11.1666 14.28 10.2799 15.1667 8.66659 15.1667ZM5.83325 10.5V12.6667C5.83325 13.72 6.27992 14.1667 7.33325 14.1667H8.66659C9.71992 14.1667 10.1666 13.72 10.1666 12.6667V10.5H5.83325Z"
          fill="#110F0E"
        />
        <path
          d="M12 12.5003H10.6667C10.3933 12.5003 10.1667 12.2737 10.1667 12.0003V10.5003H5.83333V12.0003C5.83333 12.2737 5.60667 12.5003 5.33333 12.5003H4C2.38667 12.5003 1.5 11.6137 1.5 10.0003V6.66699C1.5 5.05366 2.38667 4.16699 4 4.16699H12C13.6133 4.16699 14.5 5.05366 14.5 6.66699V10.0003C14.5 11.6137 13.6133 12.5003 12 12.5003ZM11.1667 11.5003H12C13.0533 11.5003 13.5 11.0537 13.5 10.0003V6.66699C13.5 5.61366 13.0533 5.16699 12 5.16699H4C2.94667 5.16699 2.5 5.61366 2.5 6.66699V10.0003C2.5 11.0537 2.94667 11.5003 4 11.5003H4.83333V10.0003C4.83333 9.72699 5.06 9.50033 5.33333 9.50033H10.6667C10.94 9.50033 11.1667 9.72699 11.1667 10.0003V11.5003Z"
          fill="#110F0E"
        />
        <path
          d="M11.3334 10.5H4.66675C4.39341 10.5 4.16675 10.2733 4.16675 10C4.16675 9.72667 4.39341 9.5 4.66675 9.5H11.3334C11.6067 9.5 11.8334 9.72667 11.8334 10C11.8334 10.2733 11.6067 10.5 11.3334 10.5Z"
          fill="#110F0E"
        />
        <path
          d="M6.66675 7.83301H4.66675C4.39341 7.83301 4.16675 7.60634 4.16675 7.33301C4.16675 7.05967 4.39341 6.83301 4.66675 6.83301H6.66675C6.94008 6.83301 7.16675 7.05967 7.16675 7.33301C7.16675 7.60634 6.94008 7.83301 6.66675 7.83301Z"
          fill="#110F0E"
        />
      </g>
    </svg>
  );
}
