import styled from "styled-components";
import image from "Assets/Images/background.png";

export const LoginWrapper = styled.div`
  height: 100vh;
  background: url(${image});
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-spin-dot-item {
    background-color: #d91e49 !important;
  }
`;
export const LoginCard = styled.div`
  width: 550px;
  background: #fafafa;
  border-radius: 0px;
  padding: 60px 20px 72px;
  @media (max-width: 768px) {
    padding: 100px 20px;
    width: 80%;
  }

  .check-svg {
    position: relative;
    padding: 20px;
    top: 5px;
    left: 40%;
    border-radius: 50px;
    opacity: 0px;
    background: rgba(253, 250, 231, 1);
    align-item: center;
    text-align: center;
  }

  .forget-paragraph {
    font-family: "Nunito Sans";
    font-size: 13px;
    font-weight: 400;
    line-height: 18.2px;
    text-align: center;
    color: rgba(17, 15, 14, 1);

    font-family: Nunito Sans;
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: center;
  }
  .login-btn {
    background-color: #ffd936;
    margin-top: 16px;
    border: none;
    font-weight: 600;
    color: #212121;
    width: 70%;
    cursor: pointer;
    margin-inline: auto;
    border-radius: 4px;
    padding: 11px 19px;
    line-height: 13px;
    &:hover {
      background-color: #ffd936cc;
    }
  }
`;

export const LoginHeading = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 8px;
  h5.login-heading {
    font-family: "Montserrat";
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
    margin: 0px;
  }
`;
export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  .ant-form {
    width: 50%;
  }
  .ant-input {
    box-sizing: border-box;
    border-radius: 4px;
    height: 44px;
    background: none;
  }
  .ant-form-item {
    width: 100%;
  }
  .register_link {
    padding: 20px 0px;
  }
  .login-input-wrapper {
    margin-block: 1rem;
    margin-inline: auto;
    width: 75%;
  }

  .forget-password {
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 600;
    line-height: 10px;
    text-align: right;
    margin-top: 10px;
    cursor: pointer;
    color: rgba(164, 148, 13, 1);
  }
  .login-input-wrapper .ant-typography {
    text-align: left;
  }
  .fyoWZN {
    margin-top: 0rem !important;
  }
  [ant-click-animating-without-extra-node="true"]:after {
    content: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
  .ant-input-affix-wrapper > input.ant-input {
    height: 33px;
  }
  .ant-input-affix-wrapper {
    height: 44px;
    border-radius: 4px;
  }
  .login-btn {
    background-color: rgba(231, 209, 19, 1);
    border-radius: 2px;
    margin-top: 2.5rem;
    width: 80%;
    border: none;

    height: auto;
    font-weight: 600;
    color: #212121;
  }

  @media (max-width: 768px) {
    .ant-form {
      width: 100%;
    }
    .login-input-wrapper {
      margin-block: 1rem;
      margin-inline: auto;
      width: 85%;
    }
    .login-btn {
      width: 60%;
    }
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-spin-dot-item {
    background-color: #ffc704 !important;
  }
`;
