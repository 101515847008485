import CloseIcon from "Assets/Icons/CloseIcon";
import Print from "Assets/Icons/Print";

export const data = (onClose, onPrint) => [
  {
    Icon: Print,
    onClick: onPrint,
  },
  {
    Icon: CloseIcon,
    onClick: onClose,
  },
];
