import { configureStore } from "@reduxjs/toolkit"; 
import appReducer from "./app";
import tabReducer from "./app/reducers/tabsSlice";

export const store = configureStore({
  reducer: {
    app: appReducer,
    tabs: tabReducer
  }
});
