import { Table } from 'antd';
import React, { useState, useEffect } from 'react';
import BlueCheck from 'Assets/Icons/BlueCheck.jsx';
import MonthlyOverview from 'Pages/Dashboard/Modals/MonthlyOverview/index.jsx';
import { fetchDataBillingMonth } from 'redux/app/url.js';
import '../styles.js';
import formattedAmount from 'util/formattedString.js';
import { useDispatch, useSelector } from 'react-redux';
import Airtable from 'airtable';
import { selectedUnit } from 'redux/app/reducers/tabsSlice.js';
import { apiData } from 'redux/app/index.js';

const getData = (data) => {
  return data
    ?.map((d, i) => ({
      key: i,
      dates: d?.['Dates'],
      unit_number: d?.['Unit No'],
      total_nights: d?.['Nights'] ?? '-',
      guest_name: d?.['Guest Name']?.replace(/[*#]/g, '').trim(),
      owner_occupied: d?.['Owner  Occupied'],
      nightly_rate: formattedAmount(d?.['Nightly Rate'], true),
      booking_revenue:
        d?.['BookedRevenue'] !== 0
          ? formattedAmount(d?.['BookedRevenue'], true)
          : '-',
    }))
    .sort((a, b) => {
      const firstLetterA = parseInt(a.dates?.match(/(\d+)\s*-/)[1]);
      const firstLetterB = parseInt(b.dates?.match(/(\d+)\s*-/)[1]);
      if (firstLetterA < firstLetterB) {
        return -1;
      } else if (firstLetterA > firstLetterB) {
        return 1;
      } else {
        return 0;
      }
    });
};

const columns = [
  {
    title: 'Dates',
    dataIndex: 'dates',
    key: 'dates',
    className: 'boldColumn',
  },
  {
    title: 'Nights',
    dataIndex: 'total_nights',
    key: 'total_nights',
  },
  {
    title: '⁤  Unit No',
    dataIndex: 'unit_number',
    key: 'unit_number',
  },
  {
    title: 'Guest Name  ',
    dataIndex: 'guest_name',
    key: 'guest_name',
    className: 'boldColumn',
  },
  {
    title: 'Booked  Revenue',
    dataIndex: 'booking_revenue',
    key: 'booking_revenue',
    className: 'boldColumn',
  },
  {
    title: 'Nightly Rate',
    dataIndex: 'nightly_rate',
    key: 'nightly_rate',
  },
  {
    title: 'Owner  Occupied',
    dataIndex: 'owner_occupied',
    key: 'owner_occupied',
    render: (text) =>
      text ? (
        <BlueCheck color='#0098EE' className='table-content-tick-icon' />
      ) : (
        <span className='empty-indicator'>_</span>
      ),
  },
];

const TableContent = ({ month }) => {
  const apiKeys = useSelector(apiData);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [billingData, setBillingData] = useState();
  const baseId = useSelector(selectedUnit);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async (base) => {
      try {
        setBillingData(await fetchDataBillingMonth(month, base, dispatch));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (apiKeys) {
      let base = new Airtable({
        apiKey: apiKeys.apiKay
          ? apiKeys.apiKay
          : localStorage.getItem('apiKey'),
      }).base(apiKeys.baseId ? apiKeys.baseId : localStorage.getItem('baseId'));
      fetchData(base);
    }
  }, [month, baseId]);

  const handleRowClick = (index) => {
    setSelectedRowData(billingData[index]);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className='content-table-container'>
        <Table
          dataSource={getData(billingData)}
          columns={columns}
          pagination={false}
          onRow={({ key }) => ({
            onClick: () => handleRowClick(key),
          })}
        />
      </div>

      <MonthlyOverview
        rowData={selectedRowData}
        visible={isModalVisible}
        onClose={handleModalClose}
      />
    </>
  );
};

export default TableContent;
