import { ModelDataCardWraper, SubHeadingWraper } from "./styles";

const ModelDataCard = ({ title, value }) => {
  return (
    <ModelDataCardWraper>
      <div className="card-title">{title}</div>
      <div className="card-value">{value}</div>
    </ModelDataCardWraper>
  );
};

export default ModelDataCard;
