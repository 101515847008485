// styles.js
import styled from "styled-components";

export const YearlyWrapper = styled.div`
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 248px !important;
    font-family: "Montserrat";
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    background-color: #fff;
  }
  .yearly-dropdown {
    width: 248px !important;
    font-family: "Montserrat";
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    background-color: #fff;
  }
`;
