import styled from "styled-components";

export const ButtonWrapper = styled.div`
  // Add common styles for both primary and secondary buttons here
  .ant-btn {
    border-radius: 5px;
    font-family: "Montserrat";
    transition: background-color 0.7s;
  }

  .primary-button {
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    text-align: center;
    background-color: #ffd936;
    color: ${(props) => props.theme.colors.black_900};
    padding: 13px 22px;
    border: none;
    height: fit-content;
    &:hover {
      background-color: #ffd936cc;
    }
  }

  // .secondary-button {
  //   font-size: 14px;
  //   font-weight: 700;
  //   background-color: ${(props) => props.theme.colors.brown_200};
  //   color: ${(props) => props.theme.colors.white};
  //   padding: 12px 32px;
  //   border: none;
  //   box-shadow: 0px 1.059px 21.189px 0px rgba(255, 255, 255, 0.55);
  //   height: fit-content;
  //   line-height: 16px;
  //   margin: 12px 0px 12px 0px;
  // }

  // .ant-btn:hover {
  //   background-color: ${(props) => props.theme.colors.yellowTerniary};
  //   color: ${(props) => props.theme.colors.black_900};
  // }
`;
