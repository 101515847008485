import LoadingSpinner from "Components/Shared/LoadingSpinner";
import formattedAmount from "util/formattedString";
import getPercentValue from "util/getPercentage";

export const data = (cardsData) => {
  return [
    {
      key: 1,
      card: [
        {
          cardkey: 1,
          cardtitle: "Nights Occupied",
          tooltipTitile: `Nights Occupied = guest nights + owner nights (if any) for the month`,
          cardvalue: cardsData?.Nights || "-",
        },
        {
          cardkey: 2,
          cardtitle: "Avg Nightly Rate",
          cardvalue: formattedAmount(cardsData?.["Avg Nightly Rate"], true),
        },
        {
          cardkey: 3,
          cardtitle: "Occupancy Rate",
          tooltipTitile: ` Split unit rentals may yield monthly occupancy rates > 100%`,
          cardvalue: cardsData?.["Mthly Occupancy Rate²"]
            ? getPercentValue(cardsData?.["Mthly Occupancy Rate²"])
            : "-",
        },
      ],
    },
    {
      key: 2,
      card: [
        {
          cardkey: 4,
          cardtitle: "Monthly Revenue",
          cardvalue: formattedAmount(cardsData?.["Monthly Revenue"]),
        },
        {
          cardkey: 5,
          cardtitle: "Lodging Tax",
          cardvalue: formattedAmount(cardsData?.["Lodging Tax"]),
        },
        {
          cardkey: 6,
          cardtitle: "Net of Taxes",
          cardvalue: formattedAmount(cardsData?.["Net of Taxes"]),
        },
      ],
    },
    {
      key: 3,
      card: [
        {
          cardkey: 7,
          cardtitle: "Owner revenue",
          cardvalue: formattedAmount(cardsData?.["Revenue Split  ( 𝟩𝟢﹪)"]),
        },
        {
          cardkey: 8,
          cardtitle: "HOA Fees",
          tooltipTitile: `HOA Fees include any outstanding balances from prior months & are netted from the Owner Revenue`,
          cardvalue: formattedAmount(cardsData?.["HOA Fees³"], true),
        },
        {
          cardkey: 9,
          cardtitle: "HOA Balance",
          tooltipTitile: `  HOA Balance(s), if any, roll forward to subsequent months until fully settled`,
          cardvalue: formattedAmount(cardsData?.["HOA Balance⁴"], true),
        },
        {
          cardkey: 10,
          cardtitle: "Owner Payout",
          tooltipTitile: `  Net of HOA costs`,
          cardvalue: formattedAmount(cardsData?.["Owner Payout⁵"], true),
        },
      ],
    },
  ];
};
