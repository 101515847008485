import React from "react";
import {
  Outlet,
  useNavigate,
  Link,
  NavLink,
  useLocation,
} from "react-router-dom";
import { useState, useEffect } from "react";
import { Col, Image, Layout, Menu, Row } from "antd";
import { Select } from "antd";
import user_avatar from "Assets/Images/user_avatar.png";
import { useSelector } from "react-redux";
import Paragraph from "Components/Shared/Paragraph";
import { setApiData } from "redux/app";
import { useDispatch } from "react-redux";
import { DropDownWrapper } from "Pages/Dashboard/Monthlystatement/Header/styles";
import { Dropdown, Space } from "antd";
import { Images } from "constants/images";
import Yearly from "Assets/Icons/YearlyOverview";
import monthly from "Assets/Icons/MonthlyOverview";
import Users from "Assets/Icons/users";

import {
  selectedMonth,
  selectedUnit,
  setSelectedUnit,
  unitsList,
} from "redux/app/reducers/tabsSlice";
import { TableListAction } from "redux/app/Actions/Auth";
import "./layout.css";
import { YearlyDropdown } from "Components/Shared/YearlyDropdown";
import { DropDownMonthly } from "Components/Shared/MonthlyDropDown/monthlyDropDown";
import { useDebounce } from "../Hooks/useDebounce";
const { Sider } = Layout;

const sideBarLinks = [
  {
    id: 1,
    label: "Yearly Overview",
    to: "/dashboard",
    icon: Yearly,
  },
  {
    id: 2,
    label: "Monthly Statement",
    to: "/monthly-statement",
    icon: monthly,
  },
];

const AppLayout = (props) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState("1");
  const [bottomMenu, setBottomMenu] = useState("0");
  const dispatch = useDispatch();
  const data = useSelector(unitsList);
  const activeUnit = useSelector(selectedUnit);
  const navigate = useNavigate();
  const location = useLocation();
  const [showForm, setShowForm] = useState(
    JSON.parse(localStorage.getItem("show"))
  );
  const options =
    data?.length > 0
      ? data?.map((item) => ({ label: item.name, value: item.id }))
      : [];
  const [baseId, setBaseId] = useState(localStorage.getItem("baseId"));
  const userEmail = localStorage.getItem("user_email");
  const userName = localStorage.getItem("user_name");

  const token = localStorage.getItem("access_token");
  const userType = localStorage.getItem("userType");

  // Debounce the TableListAction call
  // const debouncedToken = useDebounce(token, 1000);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      if (location.pathname === "/") {
        navigate("/dashboard");
      }
    }
  }, [token, navigate, location.pathname]);

  // useEffect(() => {
  //   if (token && data?.length > 0) {
  //     console.log("data in layout", data);
  //     console.log("token dispacher", token);
  //     dispatch(TableListAction());
  //     const storedBaseId = localStorage.getItem("baseId");
  //     if (storedBaseId) {
  //       dispatch(setSelectedUnit(storedBaseId));
  //     } else if (userType === "ADMIN" && options.length > 0) {
  //       // Set default unit for admin if none is stored
  //       const defaultUnit = options[0].value;
  //       localStorage.setItem("baseId", defaultUnit);
  //       dispatch(setSelectedUnit(defaultUnit));
  //       setBaseId(defaultUnit);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (token) {
      dispatch(TableListAction());
      dispatch(setSelectedUnit(localStorage.getItem("baseId")));
    }
  }, []);

  const handleMenuSelect = (item) => {
    setSelectedMenuItem(item.key);
    localStorage.setItem("show", false);
    setShowForm(false);
  };

  const handleBottomMenuClick = (key) => {
    if (key === 0 || key === "0") {
      navigate("/change-password");
    }
    setBottomMenu(key);
  };

  const clearStorage = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("apiKey");
    localStorage.removeItem("baseId");
    localStorage.removeItem("user_email");
    localStorage.removeItem("userType");
    localStorage.setItem("show", false);
    navigate("/login");
  };

  const handleChange = (value) => {
    dispatch(
      setApiData({ apiKey: localStorage.getItem("apiKey"), baseId: value })
    );
    localStorage.setItem("baseId", value);
    const search = options.find((obj, index) => obj.value === value);
    dispatch(setSelectedUnit(value));
    setBaseId(value);
    localStorage.setItem("show", false);
    setShowForm(false);
  };

  const items = [
    {
      label: (
        <div
          target="_blank"
          onClick={() => {
            setShowForm(true);
            setBottomMenu(0);
          }}
          rel="noopener noreferrer"
          className={`profile-icon-holder ${
            bottomMenu === "0" ? "selected" : ""
          }`}
        >
          Change password
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div
          target="_blank"
          onClick={() => {
            clearStorage();
            setBottomMenu(1);
          }}
          rel="noopener noreferrer"
          className={`profile-icon-holder ${
            bottomMenu === "1" ? "selected" : ""
          }`}
        >
          <Link to="/login">Logout</Link>
        </div>
      ),
      key: "1",
    },
  ];

  const toggleDropdown = () => {
    setDropdownVisible((prevState) => !prevState);
  };

  const currentSelectedUnit = options?.find(
    (item) => item.value === activeUnit
  );
  const monthSelected = useSelector(selectedMonth);

  return (
    <Layout style={{ background: "white" }}>
      <Sider width={280}>
        <Menu
          defaultSelectedKeys={[selectedMenuItem]}
          selectedKeys={[selectedMenuItem]}
          onSelect={handleMenuSelect}
          className="custom-menu"
          style={{ fontSize: "14px", padding: "12px 0" }}
        >
          <div className="logo">
            <Image
              className="logo-image"
              preview={false}
              src={Images.logo}
              alt="logo"
            />
          </div>
          {userType === "ADMIN" && (
            <div
              style={{
                paddingBottom: "18px",
                borderBottom: "1px solid #E1E1E1",
              }}
              className="user-info-user-name"
            >
              <span>
                Unit Owner:{" "}
                {currentSelectedUnit?.label === "Unit 723 / 725"
                  ? "Adcox"
                  : currentSelectedUnit?.label === "Unit 611 / 613" && "Fuqua"}
              </span>
            </div>
          )}

          {userType === "ADMIN" && options.length > 0 && (
            <>
              <Paragraph className={"selectRegular"}>Select Unit</Paragraph>
              <DropDownWrapper>
                <Select
                  getPopupContainer={(trigger) => trigger.parentElement}
                  className="header-custom-select"
                  placeholder="Select Base Id"
                  options={options}
                  onChange={handleChange}
                  value={activeUnit}
                />
              </DropDownWrapper>
            </>
          )}

          {(userType === "ADMIN" || userType === "OWNER") &&
            options.length > 0 && (
              <div style={{ marginBottom: "20px" }}>
                <Paragraph className={"selectRegular"}>
                  Select {location.pathname === "/dashboard" ? "Year" : "Month"}
                </Paragraph>
                {location.pathname === "/dashboard" ? (
                  <YearlyDropdown
                    getPopupContainer={(triggerNode) =>
                      triggerNode.parentElement
                    }
                  />
                ) : (
                  <DropDownMonthly
                    {...props}
                    monthSelected={monthSelected?.replace(/\s*\(p\)/g, "")}
                  />
                )}
              </div>
            )}

          {sideBarLinks.map(({ label, id, to, icon: Icon }) => (
            <NavLink
              className="sidebar-link"
              to={to}
              key={id}
              style={{ marginBottom: "24px" }}
            >
              <Menu.Item
                key={id}
                className={`menu-item ${
                  selectedMenuItem === id.toString() ? "selected" : ""
                }`}
                icon={
                  <Icon
                    className={`menu-icon ${
                      selectedMenuItem === id.toString() ? "selected" : ""
                    }`}
                  />
                }
              >
                {label}
              </Menu.Item>
            </NavLink>
          ))}

          {userType === "ADMIN" && (
            <NavLink
              className="sidebar-link"
              to="/user-manager"
              style={{ marginBottom: "24px" }}
            >
              <Menu.Item
                key="3"
                className={`menu-item ${
                  selectedMenuItem === "3" ? "selected" : ""
                }`}
                icon={
                  <Users
                    className={`menu-icon ${
                      selectedMenuItem === "3" ? "selected" : ""
                    }`}
                  />
                }
              >
                Users
              </Menu.Item>
            </NavLink>
          )}

          <Row
            justify={"space-between"}
            style={{
              position: "fixed",
              bottom: 16,
              width: "244px",
              borderTop: "1px solid #E9E9E9",
              paddingTop: "12px",
            }}
          >
            <Col sm={3} md={3} lg={3} xl={3}>
              <div className="user-profile-img">
                <Image src={user_avatar} preview={false} />
              </div>
            </Col>
            <Col sm={20} md={20} lg={20} xl={20}>
              <div className="profile-data-holder">
                <a>
                  <Row justify={"space-between"}>
                    <Col>
                      <Space>
                        <div className="profile-text-holder">
                          <p className="profile-name">{userName ?? ""}</p>
                        </div>
                      </Space>
                      <div>
                        <p className="profile-email">{userEmail ?? ""}</p>
                      </div>
                    </Col>
                    <Col>
                      <Dropdown
                        placement="topRight"
                        overlayClassName="bottom-menu-overlay"
                        overlayStyle={{
                          width: "220px",
                          textAlign: "center",
                          borderRadius: "8px",
                        }}
                        className="bottom-menu"
                        getPopupContainer={(triggerNode) =>
                          triggerNode.parentElement
                        }
                        overlay={
                          <Menu>
                            {items.map((item) => (
                              <Menu.Item
                                key={item.key}
                                onClick={() => handleBottomMenuClick(item.key)}
                              >
                                {item.label}
                              </Menu.Item>
                            ))}
                          </Menu>
                        }
                        trigger={["click"]}
                      >
                        <div
                          className="bottom-menu-icon"
                          onClick={toggleDropdown}
                        >
                          <img src={Images.dots} alt="menu" />
                        </div>
                      </Dropdown>
                    </Col>
                  </Row>
                </a>
              </div>
            </Col>
          </Row>
        </Menu>
      </Sider>
      <Layout style={{ background: "white", position: "relative" }}>
        <div className="dashboard-parent">
          <Outlet />
          {props.children}
        </div>
      </Layout>
    </Layout>
  );
};

export default AppLayout;

// useEffect(() => {
//   if (token) {
//     dispatch(TableListAction());
//     dispatch(setSelectedUnit(localStorage.getItem("baseId")));
//   }
// }, []);
