const SingleCard = ({ fValue, sValue, fTitle, sTitle }) => {
  return (
    <div className="summary-2x-row-container">
      <div className="summary-2x-row-content">
        <div className="summary-single-card-title">{fTitle}</div>
        <div className="summary-single-card-value">{fValue || '-'}</div>
      </div>
      <div className="summary-2x-row-content">
        <div className="summary-single-card-title">{sTitle}</div>
        <div className="summary-single-card-value">{sValue || '-'}</div>
      </div>
    </div>
  );
};

export default SingleCard;
