import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  changeSelectedMonth,
  selectedMonth,
} from "redux/app/reducers/tabsSlice";
import { generateDateOptions } from "../../../util/generateYear";

const { Option } = Select;

export const DropDownMonthly = ({ monthSelected, ...props }) => {
  const [options, setOptions] = useState([]);
  const [baseId, setBaseId] = useState(localStorage.getItem("baseId"));
  const dispatch = useDispatch();

  useEffect(() => {
    const updateOptions = () => {
      const latestBaseId = localStorage.getItem("baseId");
      if (latestBaseId !== baseId) {
        console.log(`Base ID updated from local storage: ${latestBaseId}`);
        setBaseId(latestBaseId);
        const generatedOptions = generateDateOptions(latestBaseId);
        setOptions(generatedOptions);
      }
    };

    updateOptions();

    const intervalId = setInterval(updateOptions, 500);

    return () => clearInterval(intervalId);
  }, [baseId]);

  const handleChange = (value) => {
    dispatch(changeSelectedMonth(value));
  };

  return (
    <Select
      showSearch
      defaultValue={monthSelected}
      className="header-custom-select"
      onChange={handleChange}
      value={monthSelected}
    >
      {options.map((each) => (
        <Option value={each.value} key={each.value}>
          {each.label}
        </Option>
      ))}
    </Select>
  );
};
