import TableContent from "./TableContent";
import "./table.css";

const RecapTable = (props) => {
  return (
    <div className="table-section-container">
      <div className="recap-table-heading">Monthly Insights</div>
      <TableContent {...props}/>
    </div>
  );
};

export default RecapTable;
