const theme = {
  colors: {
    black: "#110F0E",
    black_900: " #212121",
    grey_100: "#FAFAFA",
    grey_200: "#FF9900",
    grey_300: "#B56D00",
    yellowPrimary: "#FFDC00",
    yellowSecondry: "#FFFCE6",
    yellowTerniary: "#E7D113",
  },

  font: {
    family: "Poppins",
    weight: {
      thin: 100,
      extraLight: 200,
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
      extraBold: 800,
    },
  },

  media: {
    md: 768,
    sm: 500,
    lg: 968,
    xl: 1440,
  },

  utils: {},
};

export default theme;
