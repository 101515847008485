import { useEffect, useState } from "react";
import { Form, Input, Image, Button, Row, Col, Typography } from "antd";
import { LoginCard, LoginWrapper, FormWrapper, InputFeild } from "./styles";
import { useNavigate } from "react-router-dom";
import logo from "../../../Assets/Logos/Logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { LoginAction, PreLoginAction } from "redux/app/Actions/Auth";
import { loading as stateLoading } from "redux/app";
import LoadingSpinner from "Components/Shared/LoadingSpinner";
import PageLoader from "Components/Shared/PageSpinner";

const Login = () => {
  const navigate = useNavigate();
  const loading = useSelector(stateLoading);
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  }, []);
  const handleLogin = () => {
    dispatch(LoginAction(navigate, { email: email, password: password }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin();
  };

  return (
    <LoginWrapper>
      <LoginCard>
        <Row>
          <Col span={24}>
            <div style={{ textAlign: "center" }}>
              <Image src={logo} preview={false} />
            </div>
          </Col>
        </Row>
        <FormWrapper>
          <Form onSubmit={handleSubmit}>
            <Form.Item wrapperCol={{ span: 24 }}>
              <div>
                <div className="login-input-wrapper">
                  <Typography.Title level={5}>Email</Typography.Title>
                  <Input
                    type="text"
                    placeholder="Enter your email address"
                    onChange={(e) => setEmail(e.target.value)}
                  ></Input>
                </div>
                <div className="login-input-wrapper">
                  <Typography.Title level={5}>Password</Typography.Title>
                  <Input.Password
                    placeholder="••••••••••••••"
                    onChange={(e) => setPassword(e.target.value)}
                    style={{ lineHeight: "32px" }}
                  ></Input.Password>
                  <p
                    className="forget-password"
                    onClick={() => {
                      navigate("/forget-password");
                    }}
                  >
                    {" "}
                    Forgot password?
                  </p>
                </div>
                <Button
                  className="login-btn"
                  onClick={handleLogin}
                  type="primary"
                  loading={loading}
                  htmlType="submit"
                >
                  Login
                </Button>
              </div>
            </Form.Item>
          </Form>
        </FormWrapper>
      </LoginCard>
    </LoginWrapper>
  );
};

export default Login;
