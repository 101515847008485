export default function InfoSuperscript(props) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.8">
        <path
          d="M6.99996 0.728842C3.54079 0.728842 0.729126 3.54051 0.729126 6.99967C0.729126 10.4588 3.54079 13.2705 6.99996 13.2705C10.4591 13.2705 13.2708 10.4588 13.2708 6.99967C13.2708 3.54051 10.4591 0.728842 6.99996 0.728842ZM6.99996 12.3955C4.02496 12.3955 1.60413 9.97467 1.60413 6.99967C1.60413 4.02467 4.02496 1.60384 6.99996 1.60384C9.97496 1.60384 12.3958 4.02467 12.3958 6.99967C12.3958 9.97467 9.97496 12.3955 6.99996 12.3955Z"
          fill="#110F0E"
        />
        <path
          d="M7 5.97884C6.76083 5.97884 6.5625 6.17717 6.5625 6.41634V9.33301C6.5625 9.57217 6.76083 9.77051 7 9.77051C7.23917 9.77051 7.4375 9.57217 7.4375 9.33301V6.41634C7.4375 6.17717 7.23917 5.97884 7 5.97884Z"
          fill="#110F0E"
        />
        <path
          d="M6.99996 4.08332C6.92413 4.08332 6.84829 4.10082 6.77829 4.12999C6.70829 4.15915 6.64413 4.19999 6.58579 4.25249C6.53329 4.31082 6.49246 4.36915 6.46329 4.44499C6.43413 4.51499 6.41663 4.59082 6.41663 4.66665C6.41663 4.74249 6.43413 4.81832 6.46329 4.88832C6.49246 4.95832 6.53329 5.02249 6.58579 5.08082C6.64413 5.13332 6.70829 5.17415 6.77829 5.20332C6.91829 5.26165 7.08163 5.26165 7.22163 5.20332C7.29163 5.17415 7.35579 5.13332 7.41413 5.08082C7.46663 5.02249 7.50746 4.95832 7.53663 4.88832C7.56579 4.81832 7.58329 4.74249 7.58329 4.66665C7.58329 4.59082 7.56579 4.51499 7.53663 4.44499C7.50746 4.36915 7.46663 4.31082 7.41413 4.25249C7.35579 4.19999 7.29163 4.15915 7.22163 4.12999C7.15163 4.10082 7.07579 4.08332 6.99996 4.08332Z"
          fill="#110F0E"
        />
      </g>
    </svg>
  );
}
