import { useState, useEffect } from "react";
import { data } from "./data";
import { SummaryWrapper } from "./styles";
import { Col, Image, Row } from "antd";
import SummaryCard from "Components/Shared/SummaryCard";
import { fetchSpecificMonthData } from "redux/app/url";
import Airtable from 'airtable'
import { useSelector } from 'react-redux';
import {apiData} from 'redux/app/index'
import { Images } from "constants/images";

const Summary = ({ month }) => {
  const apiKeys   = useSelector(apiData);
  const [monthlyData, setMonthlyData] = useState(null);
  useEffect(() => {
    const fetchData = async (base) => {
      try {
        setMonthlyData(await fetchSpecificMonthData(month, base));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    if(apiKeys){
      let base = new Airtable({ apiKey: apiKeys.apiKay ? apiKeys.apiKay : localStorage.getItem("apiKey") }).base(apiKeys.baseId ?apiKeys.baseId: localStorage.getItem("baseId"));
      fetchData(base);
    }
   
  }, [month]);
  return (
    <SummaryWrapper>
      <Row>
        <Col xl={18} lg={18} md={16}>
          <div className="monthly-summary-first-row">
            {data(monthlyData)
              .slice(0, 2)
              .map((item, index) => (
                <Row key={item.key} className="summary-card-holder">
                  {item.card.map((cardItem, cardIndex) => (
                    <SummaryCard
                      key={cardItem.cardkey}
                      cardid={cardItem.cardkey}
                      title={cardItem.cardtitle}
                      tooltipTitile={cardItem.tooltipTitile}
                      value={cardItem.cardvalue}
                    />
                  ))}
                </Row>
              ))}
          </div>
        </Col>
        <Col xl={6} lg={6} md={8}>
          <div className="summary-right-col">
            <div className="logo-holder">
              <Image className="logo-image"  preview={false}src={Images.logo} alt="logo" />
            </div>
          </div>
        </Col>
      </Row>
      <Row
        style={{
          marginTop: "24px",
          border: "1px solid #E7E7E7",
          borderRadius: "8px",
        }}
      >
        {data(monthlyData)
          .slice(2, 3)
          .map((item, index) => (
            <Row key={item.key} className="summary-card-holder">
              {item.card.map((cardItem, cardIndex) => (
                <SummaryCard
                  key={cardItem.cardkey}
                  cardid={cardItem.cardkey}
                  title={cardItem.cardtitle}
                  tooltipTitile={cardItem.tooltipTitile}
                  value={cardItem.cardvalue}
                />
              ))}
            </Row>
          ))}
      </Row>
    </SummaryWrapper>
  );
};
export default Summary;
