import styled from "styled-components";
import image from "../../../Assets/Images/background.png";

export const LoginWrapper = styled.div`
  height: 100vh;
  background: white;
  text-align: center;
  display: flex;
  margin-top: 50px !important;
  justify-content: center;
  align-items: center;
  .ant-spin-dot-item {
    background-color: #d91e49 !important;
  }
`;
export const LoginCard = styled.div`
  width: 50%;
  max-width: 500px;
  background: white;
  border-radius: 0px;
  position: relative;
  left: 30px;
  @media (max-width: 768px) {
    padding: 100px 20px;
    width: 80%;
  }
  .check-svg {
    position: relative;
    padding: 20px;
    top: 5px;
    left: 40%;
    border-radius: 50px;
    opacity: 0px;
    background: rgba(253, 250, 231, 1);
    align-item: center;
    text-align: center;
  }
`;

export const LoginHeading = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 36px;
  margin-bottom: 50px;
`;
export const FormWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 2rem;
  .ant-form {
    width: 50%;
  }
  h5.ant-typography {
    margin: 0px 0px 4px;
  }
  .ant-input {
    box-sizing: border-box;
    border-radius: 5px;
    height: 40px;
    background: none;
    margin-bottom: 20px;
  }
  .ant-form-item {
    width: 100%;
  }
  .register_link {
    padding: 20px 0px;
  }
  .password-input-wrapper {
    margin-block: 1rem;
    margin-inline: auto;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;

    color: rgba(17, 15, 14, 1);
  }
  .forget-password {
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 600;
    line-height: 10px;
    text-align: right;
    margin-top: 10px;
    cursor: pointer;
    color: rgba(164, 148, 13, 1);
  }
  .password-input-wrapper .ant-typography {
    text-align: left;
  }
  [ant-click-animating-without-extra-node="true"]:after {
    content: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
  .ant-input-affix-wrapper > input.ant-input {
    height: 33px;
  }
  .ant-input-affix-wrapper {
    height: 40px;
    border-radius: 4px;
    margin-bottom: 5px;
  }
  .ant-form-item-row {
    padding-bottom: 10px;
  }
  .cancel-btn {
    background-color: white;
    width: 30%;
    padding: 8px 1rem;
    border: 1px solid rgba(160, 160, 160, 1);
    height: auto;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 13px;
    color: rgba(160, 160, 160, 1);
    &:hover {
      background-color: #f2f2f2;
    }
  }
  .submit-btn {
    background-color: #FFD936;
    border: 1px solid #FFD936;
    border-radius: 5px;
    width: 30%;
    padding: 8px 1rem;
    height: auto;
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 13px;
    color: #212121;
    &:hover {
      background-color: #ffd936cc;
    }
  }
  .login-btn {
    background-color: #FFD936;
    border: 1px solid #FFD936;
    border-radius: 5px;
    margin-top: 2.5rem;
    width: 30%;
    padding: 14px 55px;
    height: auto;
    font-weight: 600;
    color: #000;
  }
  .butn-holder {
    display: flex;
    flex-direction: row;
    justify-content: end;
    gap: 4px;
  }

  @media (max-width: 768px) {
    .ant-form {
      width: 100%;
    }
    .password-input-wrapper {
      margin-block: 1rem;
      margin-inline: auto;
      width: 85%;
    }
    .submit-btn {
      width: 40%;
    }
    .cancel-btn {
      width: 40%;
    }
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-spin-dot-item {
    background-color: #ffc704 !important;
  }
`;
export const ModalWrapper = styled.div`
  .login-btn {
    background-color: #ffd936;
    border: 1px solid #ffd936;
    border-radius: 4px;
    margin-top: 16px;
    width: 30%;
    padding: 12px 55px;
    height: auto;
    font-weight: 600;
    color: #000;
    line-height: 13px;
    &:hover {
      background-color: #ffd936cc;
    }
  }
  h5 {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 21px;
    line-height: 25.83px;
    text-align: center;
    margin-bottom: 0px;
  }
`;
