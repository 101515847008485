import styled from "styled-components";
import image from "../../../Assets/Images/background.png";

export const LoginWrapper = styled.div`
  height: 100vh;
  background: url(${image});
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-spin-dot-item {
    background-color: #d91e49 !important;
  }
`;
export const LoginCard = styled.div`
  width: 50%;
  max-width: 550px;
  background: #fafafa;
  border-radius: 0px;
  padding: 48px 70px 20px 70px;
  @media (max-width: 768px) {
    padding: 100px 20px;
    width: 80%;
  }
  .login-paragraph {
    font-family: "Nunito Sans";
    font-size: 13px;
    font-weight: 400;
    line-height: 18.2px;
    text-align: center;
    color: rgba(17, 15, 14, 1);
  }
`;

export const LoginHeading = styled.div`
  display: flex;
  justify-content: center;
  fontFamily: "Montserrat",
  fontSize: "22px",
  fontWeight: "600",
  margin-top: 48px;
  margin-bottom: 13px;
`;
export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .ant-form {
    width: 514px;
  }
  .ant-input {
    box-sizing: border-box;
    border-radius: 4px;
    height: 40px;
    background: none;
  }
  .ant-form-item {
    width: 100%;
  }
  .register_link {
    padding: 20px 0px;
  }
  .login-email-wrapper {
    margin-block: 0rem;
    text-align: start;
    margin-inline: auto;
    width: 90%;

    h5.ant-typography {
      font-family: "Montserrat";
      font-weight: 600;
      font-size: 13px;
      line-height: 13px;
      margin-bottom: 9px;
    }
  }

  .forget-password {
    font-family: Montserrat;
    font-size: 10px;
    font-weight: 600;
    line-height: 10px;
    text-align: right;
    margin-top: 10px;
    cursor: pointer;
    color: rgba(164, 148, 13, 1);
  }
  .login-input-wrapper .ant-typography {
    text-align: left;
  }
  .fyoWZN {
    margin-top: 0rem !important;
  }
  [ant-click-animating-without-extra-node="true"]:after {
    content: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
  .ant-input-affix-wrapper > input.ant-input {
    height: 33px;
  }
  .ant-input-affix-wrapper {
    height: 44px;
    border-radius: 4px;
  }
  .login-btn {
    background-color: #ffd936;
    border-radius: 4px;
    margin-top: 24px;
    // width: 87px;
    font-family: "Montserrat";
    font-weight: 500;
    border: none;
    padding: 9px 1rem;
    height: auto;
    color: rgba(0, 0, 0, 0.85);

    &:hover {
      background-color: #e9c72b;
    }
  }

  @media (max-width: 768px) {
    .ant-form {
      width: 100%;
    }
    .login-input-wrapper {
      margin-block: 1rem;
      margin-inline: auto;
      width: 85%;
    }
    .login-btn {
      width: 60%;
    }
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-spin-dot-item {
    background-color: #ffc704 !important;
  }
`;
