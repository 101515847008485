import { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, Typography, notification } from "antd";
import { LoginCard, LoginWrapper, FormWrapper, LoginHeading } from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ResetPasswordAction } from "redux/app/Actions/Auth";
import { loading as stateLoading } from "redux/app";
import PageLoader from "Components/Shared/PageSpinner";

const ResetPassword = () => {
  const navigate = useNavigate();
  const loading = useSelector(stateLoading);
  const dispatch = useDispatch();
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [outLine, setOutLine] = useState(false);

  const handleResetPassword = () => {
    if (password === confirmPassword) {
      dispatch(ResetPasswordAction(navigate, { password }, token));
    } else {
      setOutLine(true);
      notification.error({ message: "Passwords do not match" });
    }
  };

  return (
    <LoginWrapper>
      {loading && <PageLoader />}
      <LoginCard>
        <Row>
          <Col span={24}>
            <LoginHeading>
              <h5 className="login-heading">Reset Password</h5>
            </LoginHeading>
          </Col>
        </Row>
        <h5 className="reset-paragraph">Set a new password for your account</h5>
        <FormWrapper>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Typography.Title level={5}>New password</Typography.Title>
            <Input.Password
              placeholder="••••••••••••••"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Typography.Title level={5}>Confirm password</Typography.Title>
            <Input.Password
              placeholder="••••••••••••••"
              style={outLine ? { borderColor: "#ff0000" } : {}}
              onChange={(e) => {
                setOutLine(false);
                setConfirmPassword(e.target.value);
              }}
            />
            <Button
              className="reset-password-btn"
              onClick={handleResetPassword}
              type="primary"
              loading={loading}
            >
              Update
            </Button>
          </Form.Item>
        </FormWrapper>
      </LoginCard>
    </LoginWrapper>
  );
};

export default ResetPassword;
