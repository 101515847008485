import Airtable from "airtable";
import fetchingMonthlyData, {
  fetchDataBilling,
  fetchDataMonth,
} from "./monthly";
import formattedAmount from "util/formattedString";
import { requestCompleted, requestStart } from ".";

const fatchBase = () => {
  if (localStorage.getItem("apiKey") || localStorage.getItem("baseId")) {
    let storeBase = new Airtable({
      apiKey: localStorage.getItem("apiKey"),
    }).base(localStorage.getItem("baseId"));
    return storeBase;
  }
};

async function fetchBookingData(base) {
  base = await fatchBase();
  return new Promise((resolve, reject) => {
    const recordsArray = [];
    base("Yearly Revenue")
      .select({})
      .eachPage(
        function page(records, fetchNextPage) {
          records.forEach(function (record) {
            recordsArray.push(record.fields);
          });
          fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error(err);
            reject(err);
            return;
          }
          console.log("Fetched records:", recordsArray); // Log fetched records
          resolve(recordsArray);
        }
      );
  }).catch((error) => {
    console.error("Error fetching booking data:", error);
    return []; // Ensure an array is returned in case of error
  });
}

async function fetchRecordsSummary(year, base) {
  try {
    console.log("Fetching records summary for year:", year);
    const storeBase = await fatchBase();
    base = base ? base : storeBase;

    const bookingData = await fetchBookingData(base);
    console.log("Booking data:", bookingData);

    const filteredData = bookingData.filter(
      (each) => each.yearID === year
    )?.[0];
    console.log("Filtered data:", filteredData);

    return filteredData;
  } catch (error) {
    console.error("Error fetching booking data:", error);
  }
}

const getMonthlyData = async (year, base, dispatch) => {
  const storeBase = await fatchBase();
  base = base ? base : storeBase;
  const data = await fetchingMonthlyData(base, year, dispatch);

  const getMonthNumber = (monthID) => {
    const monthAbbreviations = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthAbbreviation = monthID?.split(" ")[0];
    return monthAbbreviations.indexOf(monthAbbreviation) + 1;
  };

  const getFullMonthNumber = (monthID) => {
    const monthAbbreviations = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const monthAbbreviation = monthID?.split(" ")[0];
    return monthAbbreviations.indexOf(monthAbbreviation) + 1;
  };

  const barChartData = data
    .map((eachMonth) => ({
      key: eachMonth["monthID"],
      label: eachMonth["⁤         Month Year"]?.substring(0, 3) ?? "",
      value: eachMonth["Revenue Split  ( 𝟩𝟢﹪)"] ?? 0,
    }))
    .filter((eachMonth) => eachMonth.label && eachMonth.value)
    .sort((a, b) => getMonthNumber(a.label) - getMonthNumber(b.label));

  const tableData = data
    .map((eachMonth) => {
      return {
        key: eachMonth["monthID"],
        avg_rate_booking:
          formattedAmount(eachMonth["Avg Booking Rate"], true) ?? "-",
        monthly_revenue:
          formattedAmount(eachMonth["Monthly Revenue"], true) ?? "-",
        monthly_year: eachMonth["monthID"]?.split(" ")[0] ?? "",
        avg_nighty_rate:
          formattedAmount(eachMonth["Avg Nightly Rate"], true) ?? "-",
        booking: eachMonth["Bookings"] || "-",
        nights: eachMonth["Nights"] || "-",
        owner_bookings: eachMonth["OwnerBookings"] ?? "no",
      };
    })
    .sort(
      (a, b) =>
        getFullMonthNumber(a.monthly_year) - getFullMonthNumber(b.monthly_year)
    );

  return { barChartData, tableData, rawData: data };
};

const fetchSpecificMonthData = async (month, base) => {
  try {
    const storeBase = await fatchBase();
    base = base ? base : storeBase;
    const monthlyData = await fetchDataMonth(base, month);
    return monthlyData[0];
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

const fetchDataBillingMonth = async (month, base, dispatch) => {
  try {
    dispatch(requestStart());
    const storeBase = await fatchBase();
    base = base ? base : storeBase;
    const billingData = await fetchDataBilling(base, month);
    dispatch(requestCompleted());
    return billingData;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export {
  getMonthlyData,
  fetchSpecificMonthData,
  fetchDataBillingMonth,
  fetchBookingData,
};

export default fetchRecordsSummary;
