import styled from "styled-components";
import image from "../../../Assets/Images/background.png";

export const LoginWrapper = styled.div`
  height: 100vh;
  background: url(${image}) no-repeat center/100% 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-spin-dot-item {
    background-color: #d91e49 !important;
  }
`;
export const LoginCard = styled.div`
  width: 50%;
  max-width: 500px;
  background: #fafafa;
  border-radius: 0px;
  padding: 60px 20px;
  @media (max-width: 768px) {
    padding: 100px 20px;
    width: 80%;
  }
`;

export const LoginHeading = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 36px;
  margin-bottom: 50px;
`;
export const FormWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  .ant-form {
    width: 514px;
  }
  .ant-input {
    box-sizing: border-box;
    border-radius: 4px;
    height: 40px;
    background: none;
  }
  .ant-form-item {
    width: 100%;
  }
  .register_link {
    padding: 20px 0px;
  }
  .login-input-wrapper {
    margin-block: 1rem;
    margin-inline: auto;
    width: 75%;
  }
  .forget-password {
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 600;
    line-height: 10px;
    text-align: right;
    margin-top: 14px;
    cursor: pointer;
    color: rgba(164, 148, 13, 1);
  }
  .login-input-wrapper .ant-typography {
    text-align: left;
  }
  [ant-click-animating-without-extra-node="true"]:after {
    content: none !important;
    outline: none !important;
    box-shadow: none !important;
  }
  .ant-input-affix-wrapper > input.ant-input {
    height: 33px;
  }
  .ant-input-affix-wrapper {
    height: 40px;
    border-radius: 4px;
    background: none;
  }
  .login-btn {
    background-color: #ffd936;
    border-radius: 4px;
    margin-top: 15px;
    width: 75%;
    margin-inline: auto;
    border: none;
    padding: 11px 27px;
    height: auto;
    font-family: "Montserrat";
    font-weight: 500;
    color: #212121;
    line-height: 13px;
    font-weight: 13px;
    font-size: 13px;
    &:hover {
      background-color: #ffd936cc;
    }
  }
  h5.ant-typography {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    margin-bottom: 9px;
  }

  @media (max-width: 768px) {
    .ant-form {
      width: 100%;
    }
    .login-input-wrapper {
      margin-block: 1rem;
      margin-inline: auto;
      width: 85%;
    }
    .login-btn {
      width: 60%;
    }
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .ant-spin-dot-item {
    background-color: #ffc704 !important;
  }
`;
