const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const generateDateOptions = (baseId) => {
  console.log(`Generating date options for base ID: ${baseId}`);

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth(); 
  const currentYear = currentDate.getFullYear();
  let startMonth, startYear;

  switch (baseId) {
    case "appn8T3UykPjpTnV2": // Base ID for 711/713
      startMonth = 6; // July (0-based index)
      startYear = 2023;
      break;
    case "appkscyvJutKmwgNB": // Base ID for 611/613
      startMonth = 0; // January (0-based index)
      startYear = 2023;
      break;
    default:
      console.log(
        `No specific date options defined for base ID: ${baseId}. Showing all available data.`
      );
      return generateAllMonthYearArray(); // Fallback to show all data
  }

  const options = [];
  let month = startMonth;
  let year = startYear;

  while (
    year < currentYear ||
    (year === currentYear && month <= currentMonth - 1)
  ) {
    options.push({
      label: `${monthNames[month]} ${year}`,
      value: `${year}-${String(month + 1).padStart(2, "0")}`,
    });

    month += 1;
    if (month > 11) {
      month = 0;
      year += 1;
    }
  }

  console.log(`Generated options:`, options);
  return options;
};

function generateAllYears() {
  const currentYear = new Date().getFullYear();
  const targetYear = 2017;
  const yearsArray = [];

  for (let year = currentYear; year >= targetYear; year--) {
    yearsArray.push(year);
  }

  return yearsArray;
}

const allYearsArray = generateAllYears();
export { allYearsArray as allYears };

function generateAllMonthYearArray() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  const monthYearArray = [];

  for (let year = currentYear; year >= 2018; year--) {
    const endMonth = year === currentYear ? currentMonth : 11;

    for (let month = endMonth; month >= 0; month--) {
      monthYearArray.push({
        label: `${monthNames[month]} ${year}`,
        value: `${year}-${String(month + 1).padStart(2, "0")}`,
      });
    }
  }

  return monthYearArray;
}

const currentMonthYear = () => {
  const currentDate = new Date();
  const currentMonth = monthNames[currentDate.getMonth()];
  const currentYear = currentDate.getFullYear();
  return `${currentMonth} ${currentYear}`;
};

export { currentMonthYear, generateAllMonthYearArray };
