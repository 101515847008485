import React, { ReactNode } from "react";
import styled from "styled-components";

// Define the styled component
export const ButtonModelWrapper = styled.button`
  text-align: center;
color:#110F0E;
background:#F8F1B6;
font-family:"Montserrat"
  font-Weight:500;
font-Size:13px;
  Line-height:16px
  margin-top: 20px;
  cursor: pointer;
border:none;
  border-radius:4px;
Padding:8px 15px 8px 15px;
Gap:5px;
display:flex;
height:fit-content;
margin-bottom:12px;

box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 1px 1px;
.link-icon{
  padding:2px 2px 0px;
}
`;
