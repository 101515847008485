import styled from "styled-components";

export const SubHeadingWraper = styled.div`
  .SubHeading {
    color: ${(props) => props.theme.colors.black};
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    margin-bottom: 20px;
    display: flex;
  }
  .SectionHeading {
    color: ${(props) => props.theme.colors.black};
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    margin-bottom: 24px;
  }
`;
