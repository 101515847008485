import axios from "axios";
import { requestStart, requestCompleted, setApiData } from "../..";
import { notification } from "antd";
import { setSelectedUnit, setUnitsList } from "redux/app/reducers/tabsSlice";

export function LoginAction(navigate, user) {
  return async (dispatch) => {
    try {
      dispatch(requestStart());
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_LOGIN}`,
          user
        )
        .then((res) => {
          if (res) {
            console.log(res, "res we are getting authenticated user data");
            localStorage.setItem("access_token", res.data.data.accessToken);
            localStorage.setItem("apiKey", res.data.data.user.apiData.apiKay);
            localStorage.setItem("baseId", res.data.data.user.apiData.baseId);
            localStorage.setItem("user_email", res.data.data.user.email);
            localStorage.setItem("user_name", res.data.data.user.name);
            localStorage.setItem("userType", res.data.data.user.airTableType);
            localStorage.setItem("show", false);
            console.log(
              res.data.data.user.name,
              "here is the name of our user"
            );
            dispatch(setApiData(res.data.data.user.apiData));
            dispatch(setSelectedUnit(res.data.data.user.apiData.baseId));
            dispatch(requestCompleted());
            navigate("/dashboard");

            notification.success({
              message: "Login successful",
            });
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            // message: error?.response?.data?.message,
            message: "Your email and password do not match",
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function ForgetPasswordAction(navigate, user) {
  return async (dispatch) => {
    try {
      dispatch(requestStart());
      axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_FORGET_PASSWORD}`,
          user
        )
        .then((res) => {
          if (res) {
            localStorage.setItem("passwordUpdate", false);
            dispatch(requestCompleted());
            navigate("/notification");
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          console.log("Error MEssage => ", error);
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    } catch (error) {
      console.log("Error MEssage Catch => ", error);

      dispatch(requestCompleted());
    }
  };
}

export function ResetPasswordAction(navigate, obj, token) {
  return async (dispatch) => {
    try {
      dispatch(requestStart());
      axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_RESET_PASSWORD}`,
          obj,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (res) {
            localStorage.setItem("passwordUpdate", true);
            dispatch(requestCompleted());
            navigate("/notification");
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}

export function ChangePasswordAction(navigate, showModal = () => {}, obj) {
  return async (dispatch) => {
    try {
      dispatch(requestStart());
      axios
        .patch(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_CHANGE_PASSWORD}`,
          obj,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          }
        )
        .then((res) => {
          if (res) {
            console.log(res, "res");
            localStorage.setItem("passwordUpdate", true);
            dispatch(requestCompleted());
            showModal();
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}
export function SignUpAction(user, closeDrawer) {
  return async (dispatch) => {
    try {
      dispatch(requestStart());
      axios
        .post(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SIGNUP}`,
          user
        )
        .then((res) => {
          if (res) {
            dispatch(requestCompleted());
            notification.success({
              message: "Owner registered successfully!",
            });
            closeDrawer();
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}
export function TableListAction() {
  return async (dispatch) => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_AIR_TABLE_IDS_LIST}`
        )
        .then((res) => {
          if (res) {
            dispatch(setUnitsList(res.data));
          }
        })
        .catch((error) => {
          dispatch(requestCompleted());
          notification.error({
            message: error?.response?.data?.message,
          });
        });
    } catch (error) {
      dispatch(requestCompleted());
    }
  };
}
