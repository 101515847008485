import { useSelector } from "react-redux";
import Header from "./Header";
import Summary from "./Summary";
import Table from "./Table";
import { loading as stateLoading } from "redux/app";
import { selectedMonth, selectedUnit } from "redux/app/reducers/tabsSlice";
import PageLoader from "Components/Shared/PageSpinner";
import { useEffect, useState } from "react";

const MonthlyStatement = () => {
  const monthSelected = useSelector(selectedMonth);
  const loading = useSelector(stateLoading);
  const baseId = useSelector(selectedUnit);
  // const userType = localStorage.getItem("userType");
  const [reloadKey, setReloadKey] = useState(0);

  useEffect(() => {
    setReloadKey((prevKey) => prevKey + 1);
  }, [baseId]);

  return (
    <div style={{ position: "relative" }}>
      {loading && <PageLoader />} {/* Show PageLoader while loading */}
      <Header />
      <Summary key={reloadKey} month={monthSelected} />
      <Table month={monthSelected} />
    </div>
  );
};

export default MonthlyStatement;
