import Login from "Pages/Auth/Login";
import React from "react";
import Theme from "Styles/theme";
import "antd/dist/antd.css";

import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import ForgetPassword from "Pages/Auth/ForgetPassword";
import AuthNotification from "Pages/Auth/AuthNotification";
import ResetPassword from "Pages/Auth/ResetPassword";
import ChangePassword from "Pages/Auth/ChangePassword";
import AppLayout from "Layout/Layout";
import ManageUsers from "Pages/UserManagement/manageUsers";
import MonthlyStatement from "Pages/Dashboard/Monthlystatement";
import Content from "Pages/Dashboard/Content";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/forget-password",
    element: <ForgetPassword />,
  },
  {
    path: "/reset-password/:token",
    element: <ResetPassword />,
  },

  {
    path: "/notification",
    element: <AuthNotification />,
  },
  {
    path: "/user-manager",
    element: <ManageUsers />,
  },
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/dashboard",
        element: <Content />,
      },
      {
        path: "/monthly-statement",
        element: <MonthlyStatement />,
      },
      {
        path: "/change-password",
        element: <ChangePassword />,
      },
    ],
  },
]);

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

const LoginRedirect = ({ children }) => {
  const isAuthenticated = localStorage.getItem("token");
  return isAuthenticated ? <Navigate to="/" /> : children;
};

export default App;
